import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useUserState } from "../../context/UserContext";
import { db } from "../../services/firebase";
import {Grid} from "@material-ui/core";
import { Typography } from "../../components/Wrappers";
import Brightness1SharpIcon from '@material-ui/icons/Brightness1Sharp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Dialog from './Dialog'
import Asignar from './Asignar'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function Tema() {
  const classes = useStyles();
  const [listatemas, setListatemas] = React.useState([]);
  var { user,datos } = useUserState();

  const [listaAsignado, setListaAsignado] = React.useState([]);

  useEffect(() =>{
    db.ref('tema').on('value', function(snap) {
      console.log('sanp',snap.val());
      let lista = [];
      snap.forEach((item, i) => {
          //console.log('item',item.key);
          lista.push({...item.val(), key: item.key});
      });
      console.log('lista', lista);
      setListatemas(lista);
      //setDatos(snap.val() === null ? [] : snap.val());
    });

    db.ref('tema_asignado').on('value', function(snap){
        let listaTema = [];
        snap.forEach((item, i) => {
          listaTema.push({...item.val(), key: item.key});
        });
        setListaAsignado(listaTema);
    });

  },[]);

  return (
    <>
    <Asignar temas={listatemas} asignados={listaAsignado} />
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {listatemas.map((row) => (
            <TableRow key={row.key}>
              <TableCell>
                  <Grid container spacing={1}>
                      <Grid item xs={12} md={2}>
                          <Typography variant="button" display="block" gutterBottom>
                              Nombre
                          </Typography>
                          <Typography variant="caption" display="block" gutterBottom>
                              {row.nombre}
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                          <Typography variant="button" display="block" gutterBottom>
                              descripcion
                          </Typography>
                          <Typography variant="caption" display="block" gutterBottom>
                              {row.descripcion}
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                          <Typography variant="button" display="block" gutterBottom>
                              Fondo
                          </Typography>
                          <Typography variant="caption" display="block" gutterBottom>
                              <Brightness1SharpIcon style={{ fontSize: 43,
                                                     color: row.tema.background.default,
                                                     backgroundColor: "white"
                                                   }} />
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                          <Typography variant="button" display="block" gutterBottom>
                              Widget
                          </Typography>
                          <Typography variant="caption" display="block" gutterBottom>
                              <Brightness1SharpIcon style={{ fontSize: 43,
                                                     color: row.tema.background.widget,
                                                     backgroundColor: "white"
                                                   }} />
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                          <Typography variant="button" display="block" gutterBottom>
                              Primary
                          </Typography>
                          <Typography variant="caption" display="block" gutterBottom>
                              <Brightness1SharpIcon style={{ fontSize: 43,
                                                     color: row.tema.primary,
                                                     backgroundColor: "white"
                                                   }} />
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                          <Typography variant="button" display="block" gutterBottom>
                              Secondary
                          </Typography>
                          <Typography variant="caption" display="block" gutterBottom>
                              <Brightness1SharpIcon style={{ fontSize: 43,
                                                     color: row.tema.secondary,
                                                     backgroundColor: "white"
                                                   }} />
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                            <Typography variant="button" display="block" gutterBottom>
                                Opciones
                            </Typography>
                                <Dialog datos={{...row, key:row.key}}/>

                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className={classes.button}
                                  startIcon={<DeleteIcon />}
                                >
                                  Eliminar
                                </Button>
                      </Grid>
                   </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
