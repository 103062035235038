import React,{useEffect}  from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import {
    IconButton,
    Box,
    Grid,
    Breadcrumbs,
    Tab,
} from '@material-ui/core'
import {
    NavigateNext as NavigateNextIcon,
    ChatBubbleOutline as ChatIcon,
    AddShoppingCart as AddIcon,
    StarBorder as StarIcon,
    NaturePeopleOutlined   ,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'


// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'
import Footer from '../Footer'
import Widget from '../Widget'
import { Link, Typography } from '../../components/Wrappers'


// pages
import Dashboard from '../../pages/dashboard'
import AnotherPage from '../../pages/another-page'

// context
import { useLayoutState } from '../../context/LayoutContext'
import { useUserState,} from "../../context/UserContext";
import {db} from "../../services/firebase";

//Sidebar structure
import structure from '../Sidebar/SidebarStructure'
import Perfil from '../../pages/perfil/Perfil'
import Facebook from '../../pages/facebook/Facebook'
import fbBots from '../../pages/fb_bot/Bot'
import fbSetting from '../../pages/fb_setting/Facebooksetting'
import Reports from '../../pages/reports/Reports'
import Admin from '../../pages/admin/Admin'
import Error from '../../pages/error/Error';
import PermisoDenegado from '../../pages/permiso_denegado/PermisoDenegado';
import Secuencia from '../../pages/secuencia/Secuencia'
import Chatcenter from '../../pages/chatcenter/Chatcenter'
import OtraSesion from '../../pages/otra_sesion/OtraSesion'
import IA from '../../pages/artificial_setting/Artificial'
import Telegram from '../../pages/telegram/Telegram'
import tgSetting from '../../pages/tg_setting/Telegramsetting'
import tgBots from '../../pages/tg_bot/Bottg';
import ReportsTelegram from '../../pages/reports/ReportsTelegram';
import tgChatcenter from '../../pages/tg_chatcenter';
import OrdenesCompra from '../../pages/odenesCompra/OrdenesCompra';
import ReportsOrdenCompra from '../../pages/reports/ReportsOrdenCompra';
import EstructuraBot from '../../pages/adminBot/EstructuraBot'
import wpchatCenter from '../../pages/wp_chatcenter/Chatcenter';
import ReportsWhatsapp from '../../pages/reports/ReportsWhatsapp';
import WhatsApp from '../../pages/whatsapp/Whatsapp';
import wpBots from '../../pages/whatsapp/bot/Bot';
import wpsetting from '../../pages/whatsapp/setting/Whatsappsetting';
import ReportsInstagram from '../../pages/reports/ReportsInstagram'
import ReportsWebChat from '../../pages/reports/ReportsWebChat'
import Tema from '../../pages/tema/Tema'

//iconos
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import LanguageIcon from '@material-ui/icons/Language';
import BuildIcon from '@material-ui/icons/Build';
import HomeIcon from '@material-ui/icons/Home';
import LoadingInterface from '../LoadingInterface'
import Plan from '../../pages/plan/Plan';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DescriptionIcon from '@material-ui/icons/Description';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PersonIcon from '@material-ui/icons/Person';
import InstagramIcon from '@material-ui/icons/Instagram';

import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PaletteIcon from '@material-ui/icons/Palette';

// Tab styling

const CustomTab = withStyles(theme => ({
    root: {
        minWidth: 72,
        textTransform: 'none',
        fontWeight: 400,
    },
}))(props => <Tab {...props} />)

function Layout(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(2)
    const [anchorEl, setAnchorEl] = React.useState(null)
    var { user,datos } = useUserState();

    const[paginasn, setPaginasn]=React.useState([]);
    //sidebbar
    const[sidebarItem, setSidebarItem]=React.useState([]);

    const open = Boolean(anchorEl)
    const id = open ? 'add-section-popover' : undefined
    const handleClick = event => {
        setAnchorEl(open ? null : event.currentTarget)
    }

    // global
    var layoutState = useLayoutState()

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }


     useEffect(() =>{

        setPaginasn(datos.permisos.paginas);
        estructura(datos.permisos.paginas);

/*
        //obtener el rol del usuario actual
        db.ref('usuarios/'+user.id).once(
            'value',snapshot => {
                var datos=snapshot.val();
               let rol=datos.rol;

               //buscar las paginas permitidas
               db.ref('/roles/'+rol+'/paginas/').once(
                'value',snapshot => {
                    var paginas=snapshot.val();
                    setPaginasn(paginas);
                    estructura(paginas);


            });
        });
*/
    return () => {
        setPaginasn([]);
        setSidebarItem([]);

    }
  },[]);

  function estructura(paginas){

        let structure = [   { id: 0, label: 'DASHBOARD' ,link:"/app/dashboard", icon:<HomeIcon/>}];

        if(paginas.indexOf('plan')!== -1){ structure.push( {id: 1,label: 'Plan',link:'/app/plan',icon:<AssignmentIcon/>} ); }

              /*if(paginas.indexOf('bots')!== -1){  structure.push({id: 1, label: 'Mis Redes', link: '/app/redes', icon: <HomeIcon /> });}*/
        structure.push({id: 2, type: 'divider' });

        if(paginas.indexOf('estructurebot')!== -1){ structure.push({ id: 219, label: 'Bots', link: '/app/estructbot', icon: <RecordVoiceOverIcon /> }); }
        structure.push( { id:'chat_center_unificado', label: 'Chatcenter',icon: <ChatIcon/>, link: '/app/chatCenter'});

        if(paginas.indexOf('ordenesCompra')!== -1){ structure.push( {id: 5,label: 'Ordenes de compra', link: '/app/ordenesCompra',icon: <ShoppingCartIcon/> }); }

        structure.push({id: 222, type: 'divider' });

        structure.push({ id: 3, type:'title', label:'Reportes' });

        /*children fb*/
        var childrenfb=[];

          if(paginas.indexOf('reports')!== -1){
              childrenfb.push({id: 8,label: 'Conversaciones', link: '/app/fb/reportes/conversaciones' });
              childrenfb.push( {id: 9,label: 'Compras', link: '/app/fb/reportes/compras' });

              structure.push( { id:'fb_sidel_10', label: 'Facebook',icon: <FacebookIcon/>,children:childrenfb});
              structure.push( { id: 21, label: 'WhatsApp',icon: <WhatsAppIcon />,link: '/app/whatsapp/reportes/conversaciones' });
              structure.push( { id: 14, label: 'Telegram',icon: <TelegramIcon />, link: '/app/tgreports'  });
              structure.push( { id: "ReportesConverInstagram", label: 'Instagram',icon: <InstagramIcon />, link: '/app/instagram/reportes/conversaciones'  });
              structure.push( { id: "ReportesWebChat", label: 'WebChat',icon: <LanguageIcon />, link: '/app/chatweb/reportes/conversaciones'  });
          }



        /*structure.push( { id: 7, label: 'Whatsapp',  icon: <WhatsAppIcon /> });*/
        /*children Telegram---------------------------------------*/
      /*  structure.push({id: 'divider_telegram', type: 'divider', label:"label_divider_telegram"});
        var childrentg=[];
            if(paginas.indexOf('telegram')!== -1){ childrentg.push( {id: 11,label: 'Asistentes',link:'/app/telegram'} ); }
          /*  if(paginas.indexOf('tgchatCenter')!== -1){ childrentg.push({id: 13,label: 'ChatCenter', link: '/app/tgchatCenter' }); }*/
        /*    childrentg.push({id: 'divider_telegram_report_telegram', type: 'divider', label:"label_divider_telegram_report_telegram" });
            childrentg.push({id: 'title_telegram_report_telegram', type: 'title', label:"Reportes" });
            if(paginas.indexOf('reports')!== -1){ childrentg.push( {id: 12,label: 'Conversaciones', link: '/app/tgreports' }); }
*/





      /*  var childrenwp=[];
            if(paginas.indexOf('whatsapp')!== -1){ childrenwp.push( {id: 'assitentes_whatsapp',label: 'Asistentes',link:'/app/whatsapp/asistentes'} ); }
          /*  if(paginas.indexOf('wpchatCenter')!== -1){ childrenwp.push( {id: 20,label: 'ChatCenter',link:'/app/whatsapp/chatcenter'} ); }*/
        /*    if(paginas.indexOf('reports')!== -1){
                childrenwp.push({id: 'divider_whatsapp_reportes', type: 'divider', label:"label_divider_whatsapp_reportes" });
                childrenwp.push({id: 'reportes_whatsapp', type: 'title',label:'Reportes' });
                childrenwp.push({id: 23,label: 'Conversaciones', link: '/app/whatsapp/reportes/conversaciones' });
            }
      */

        /*structure.push( { id: 9, label: 'Website',  icon: <LanguageIcon /> });*/
        structure.push( { id: 15, type: 'divider',label:"label_15" });
        structure.push(  { id: 16,type: "title", label: 'Configuraciones' });
        if(paginas.indexOf('periflUsuario')!== -1){ structure.push( { id: 17, label: 'Mi Perfil',link:'/app/periflUsuario', icon: <PeopleAltIcon /> }); }
        if(paginas.indexOf('tema')!== -1){ structure.push( { id: 1787, label: 'Tema',link:'/app/temaedit', icon: <PaletteIcon /> }); }

        let childrenAdmin=[]
        if(paginas.indexOf('admin')!== -1){ childrenAdmin.push({ id: 18, label: 'Empresas', link: '/app/admin', icon: <BuildIcon /> }); }
      //  if(paginas.indexOf('estructurebot')!== -1){ childrenAdmin.push({ id: 19, label: 'Bots', link: '/app/estructbot', icon: <MotorcycleIcon /> }); }
        structure.push( { id: "adminConsola", label: 'Administracion',icon:<BuildIcon />,children:childrenAdmin });
        setSidebarItem(structure);

    }



    return (
        <div className={classes.root}>
            <Header history={props.history} />
            <Sidebar structure={sidebarItem} />
            <div
                className={classnames(classes.content, {
                    [classes.contentShift]: layoutState.isSidebarOpened,
                })}
            >
                <div className={classes.fakeToolbar} />
                <Widget
                    disableWidgetMenu
                    inheritHeight
                    className={classes.margin}
                    bodyClass={classes.navPadding}
                >
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap={'nowrap'}
                        style={{ overflowX: 'auto' }}
                    >
                        {sidebarItem.map(c => {
                            if (
                                !c.children &&
                                window.location.hash.includes(c.link) &&
                                c.link
                            ) {
                                return (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        key={c.id}
                                    >
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Typography variant="h4">
                                                {c.label}
                                            </Typography>
                                        </Breadcrumbs>
                                    </Box>
                                )
                            } else if (c.children) {
                                return c.children.map(currentInner => {
                                    if (
                                        window.location.hash.includes(
                                            currentInner.link
                                        )
                                    ) {
                                        return (
                                            <Breadcrumbs
                                                separator={
                                                    <NavigateNextIcon fontSize="small" />
                                                }
                                                aria-label="breadcrumb"
                                                key={c.id}
                                            >
                                                <Typography variant={'h6'}>
                                                    {c.label}
                                                </Typography>
                                                <Typography
                                                    variant={'h6'}
                                                    color="primary"
                                                >
                                                    {currentInner.label}
                                                </Typography>
                                            </Breadcrumbs>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            } else {
                                return null
                            }
                        })}

                        {/*window.location.hash.includes('/app/ecommerce') && (
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <IconButton aria-label="chat">
                                        <ChatIcon
                                            className={classes.ecommerceIcon}
                                        />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <IconButton aria-label="add_to_cart">
                                        <AddIcon
                                            className={classes.ecommerceIcon}
                                        />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <IconButton aria-label="rate">
                                        <StarIcon
                                            className={classes.ecommerceIcon}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        )*/}
                    </Grid>
                </Widget>
<Switch>
                    {

                        paginasn.length===0 ?
                            <>
                                <Route path="/app/dashboard" component={LoadingInterface}/>
                                <Route path={"/app/admin"} component={LoadingInterface}/>
                                <Route path={"/app/periflUsuario"} component={LoadingInterface}/>
                                <Route path={"/app/another-page"} component={LoadingInterface}/>
                                <Route path={"/app/facebook"} component={LoadingInterface}/>
                                <Route path={"/app/fbsetting/:id"} component={LoadingInterface}/>
                                <Route path={"/app/bots/:id"} component={LoadingInterface}/>
                                <Route path={"/app/fb/reportes/conversaciones"} component={LoadingInterface}/>
                                <Route path={"/app/secuencia"} component={LoadingInterface}/>
                                <Route path={"/app/chatCenter"} component={LoadingInterface}/>
                                <Route path={"/app/IA/:id"} component={LoadingInterface}/>
                                <Route path={"/app/telegram"} component={LoadingInterface}/>
                                <Route path={"/app/tgsetting/:id"} component={LoadingInterface}/>
                                <Route path={"/app/tgbots/:id"} component={LoadingInterface}/>
                                <Route path={"/app/plan"} component={LoadingInterface}/>
                                <Route path={"/app/tgreports"} component={LoadingInterface}/>
                                <Route path={"/app/tgChatcenter"} component={LoadingInterface}/>
                                <Route path={"/app/ordenesCompra"} component={LoadingInterface}/>
                                <Route path={"/app/fb/reportes/compras"} component={LoadingInterface}/>
                                <Route path={"/app/estructbot"} component={LoadingInterface}/>

                                <Route path={"/app/whatsapp/chatcenter"} component={LoadingInterface}/>
                                <Route path={'/app/whatsapp/reportes/conversaciones'} component={LoadingInterface}/>
                                <Route path={'/app/whatsapp/asistentes'} component={LoadingInterface}/>
                                <Route path={'/app/whatsapp/bot/:id'} component={LoadingInterface}/>
                                <Route path={'/app/whatsapp/wpsetting/:id'} component={LoadingInterface}/>

                                <Route path={'/app/instagram/reportes/conversaciones'} component={LoadingInterface}/>
                                <Route path={'/app/chatweb/reportes/conversaciones'} component={LoadingInterface}/>
                                <Route path={'/app/temaedit'} component={LoadingInterface}/>

                            </>

                        :

                        paginasn.indexOf('otra_sesion')!== -1?
                                <>
                                     <Route path="/app/dashboard" component={OtraSesion}/>
                                     <Route path={"/app/admin"} component={OtraSesion}/>
                                     <Route path={"/app/periflUsuario"} component={OtraSesion}/>
                                     <Route path={"/app/another-page"} component={OtraSesion}/>
                                     <Route path={"/app/facebook"} component={OtraSesion}/>
                                     <Route path={"/app/fbsetting/:id"} component={OtraSesion}/>
                                     <Route path={"/app/bots/:id"} component={OtraSesion}/>
                                     <Route path={"/app/fb/reportes/conversaciones"} component={OtraSesion}/>
                                     <Route path={"/app/secuencia"} component={OtraSesion}/>
                                     <Route path={"/app/chatCenter"} component={OtraSesion}/>
                                     <Route path={"app/IA/:id"} component={OtraSesion}/>
                                     <Route path={"app/telegram"} component={OtraSesion}/>
                                     <Route path={"app/tgsetting/:id"} component={OtraSesion}/>
                                     <Route path={"app/tgbots/:id"} component={OtraSesion}/>
                                     <Route path={"/app/plan"} component={OtraSesion}/>
                                     <Route path={"/app/tgreports"} component={OtraSesion}/>
                                     <Route path={"/app/tgChatcenter"} component={OtraSesion}/>
                                     <Route path={"/app/ordenesCompra"} component={OtraSesion}/>
                                     <Route path={"/app/fb/reportes/compras"} component={OtraSesion}/>
                                     <Route path={"/app/estructbot"} component={OtraSesion}/>
                                     <Route path={"/app/whatsapp/chatcenter"} component={OtraSesion}/>
                                     <Route path={"/app/whatsapp/reportes/conversaciones"} component={OtraSesion}/>
                                     <Route path={"/app/whatsapp/asistentes"} component={OtraSesion}/>
                                     <Route path={'/app/whatsapp/bot/:id'} component={OtraSesion}/>
                                     <Route path={'/app/whatsapp/wpsetting/:id'} component={OtraSesion}/>
                                     <Route path={'/app/instagram/reportes/conversaciones'} component={OtraSesion}/>
                                     <Route path={'/app/chatweb/reportes/conversaciones'} component={OtraSesion}/>
                                     <Route path={'/app/temaedit'} component={OtraSesion}/>
                                </>
                        :

                            <>
                                {paginasn.indexOf('dashboard')!== -1?      <Route path="/app/dashboard" component={Dashboard}/>       : <Route path="/app/dashboard" component={PermisoDenegado}/> }
                                {paginasn.indexOf('admin')!== -1?          <Route path={"/app/admin"} component={Admin}/>             :<Route path={"/app/admin"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('periflUsuario')!== -1?  <Route path={"/app/periflUsuario"} component={Perfil}/>    : <Route path={"/app/periflUsuario"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('another-page')!== -1?   <Route path={"/app/another-page"} component={AnotherPage}/>: <Route path={"/app/another-page"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('facebook')!== -1?       <Route path={"/app/facebook"} component={Facebook}/>       : <Route path={"/app/facebook"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('fbsetting')!== -1?      <Route path={"/app/fbsetting/:id"} component={fbSetting}/> : <Route path={"/app/fbsetting/:id"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('bots')!== -1?           <Route path={"/app/bots/:id"} component={fbBots}/>         : <Route path={"/app/bots/:id"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('reports')!== -1?        <Route path={"/app/fb/reportes/conversaciones"} component={Reports}/>   : <Route path={"/app/fb/reportes/conversaciones"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('secuencia')!== -1?      <Route path={"/app/secuencia"} component={Secuencia}/>       : <Route path={"/app/secuencia"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('chatCenter')!== -1?     <Route path={"/app/chatCenter"} component={Chatcenter}/>       : <Route path={"/app/chatCenter"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('IA')!== -1?             <Route path={"/app/IA/:id"} component={IA}/>              : <Route path={"/app/IA/:id"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('telegram')!== -1?       <Route path={"/app/telegram"} component={Telegram}/>       : <Route path={"/app/telegram"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('tgsetting')!== -1?      <Route path={"/app/tgsetting/:id"} component={tgSetting}/> : <Route path={"/app/tgsetting/:id"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('tgbots')!== -1?         <Route path={"/app/tgbots/:id"} component={tgBots}/>         : <Route path={"/app/tgbots/:id"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('plan')!== -1?         <Route path={"/app/plan"} component={Plan}/>                        : <Route path={"/app/plan"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('reports')!== -1?         <Route path={"/app/tgreports"} component={ReportsTelegram}/>  : <Route path={"/app/tgreports"} component={PermisoDenegado}/>  }
                                {paginasn.indexOf('tgchatCenter')!== -1?    <Route path={"/app/tgChatcenter"} component={tgChatcenter}/> : <Route path={"/app/tgChatcenter"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('ordenesCompra')!== -1?    <Route path={"/app/ordenesCompra"} component={OrdenesCompra}/> : <Route path={"/app/ordenesCompra"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('reports')!== -1?    <Route path={"/app/fb/reportes/compras"} component={ReportsOrdenCompra}/> : <Route path={"/app/fb/reportes/compras"} component={PermisoDenegado}/> }

                                {paginasn.indexOf('estructurebot')!== -1?    <Route path={"/app/estructbot"} component={EstructuraBot}/> : <Route path={"/app/estructbot"} component={PermisoDenegado}/>}

                                {paginasn.indexOf('wpchatCenter')!== -1?    <Route path={"/app/whatsapp/chatcenter"} component={wpchatCenter}/> : <Route path={"/app/whatsapp/chatcenter"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('reports')!== -1?    <Route path={"/app/whatsapp/reportes/conversaciones"} component={ReportsWhatsapp}/> : <Route path={"/app/whatsapp/reportes/conversaciones"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('whatsapp')!== -1?    <Route path={"/app/whatsapp/asistentes"} component={WhatsApp}/> : <Route path={"/app/whatsapp/asistentes"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('wpbots')!== -1?    <Route path={"/app/whatsapp/bot/:id"} component={wpBots}/> : <Route path={"/app/whatsapp/bot/:id"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('wpsetting')!== -1?    <Route path={"/app/whatsapp/wpsetting/:id"} component={wpsetting}/> : <Route path={"/app/whatsapp/wpsetting/:id"} component={PermisoDenegado}/> }

                                {paginasn.indexOf('reports')!== -1?    <Route path={"/app/instagram/reportes/conversaciones"} component={ReportsInstagram}/> : <Route path={"/app/instagram/reportes/conversaciones"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('reports')!== -1?    <Route path={"/app/chatweb/reportes/conversaciones"} component={ReportsWebChat}/> : <Route path={"/app/chatweb/reportes/conversaciones"} component={PermisoDenegado}/> }
                                {paginasn.indexOf('tema')!== -1?    <Route path={"/app/temaedit"} component={Tema}/> : <Route path={"/app/temaedit"} component={PermisoDenegado}/> }

                            </>
                    }
                    </Switch>

                <Footer>
                    <div>
                        <Link
                            color={'primary'}
                            href={'http://automatadg.com'}
                            target={'_blank'}
                            className={classes.link}
                        >
                           © Copyright Automata Digital. All Rights Reserved
                        </Link>
                    </div>
                </Footer>
            </div>
        </div>
    )
}

export default withRouter(Layout)
