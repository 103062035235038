import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import AddBoxIcon from '@material-ui/icons/AddBox';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import QueueIcon from '@material-ui/icons/Queue';
import { Typography } from "../../../../../components/Wrappers";
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Botones from "./Botones";
import { useUserState} from "../../../../../context/UserContext";
import { useAdminBotDispatch,
    botActual,
    useAdminBotState,
} from '../../../contexto/AdminBotContext';

import {
  maximaCantidadBotonesEnModulo,maximaCantidadCaracteresEnBotones,
  validarTextoBotonesContenidoEscrito,mensajeTextoBotonErrores
} from './../validaciones'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',

        backgroundColor: theme.palette.background.paper,
      },
      gridList: {

        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },
      title: {
        color: theme.palette.primary.light,
      },
      titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
      formControl: {
        width: "100%",
      },

      margen:{
        padding: theme.spacing(1),
      },

      colorDelete:{
          color:"#dc004e",
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },

  }),
);


function Modulos(props){

  const classes = useStyles();
  var {bot_actual,bloques} =useAdminBotState();
  var { datos } = useUserState();

  const [url,setUrl]= React.useState("");
  const [titulo,setTitulo] = React.useState("");
  const [subtitulo,setSubtitulo] = React.useState("");
  const [botones,setBotones] = React.useState([]);

  const [enviar,setEnviar] = React.useState("");
  const [nuevoTipo,setnuevoTipo] = React.useState("web_url");
  const [nuevoUrl,setNuevoUrl]= React.useState("");
  const [nuevoTitulo,setNuevoTitulo]= React.useState("");
  const [items,setItems]= React.useState([]);

  const [actualizarBotones,setActualizarBotones] = React.useState(true);
  const [errorNuevoTitulo,setErrorNuevoTitulo]= React.useState(false);
  const [errorNuevoUrl,setErrorNuevoUrl] = React.useState(false);

  const [tituloBotonRepetido,SetTituloBotonRepetido] = React.useState(false);
  const [mensajeCodigoValidacion,setMensajeCodigoValidacion]=React.useState(-1);

  const [contador,setContador]=React.useState(0);
  const [defaultAction,setDefaultAction]=React.useState({});

  const [default_action_url,setDefault_action_url]=React.useState("");
 
  useEffect(() =>{
      if(bloques!==null)
      {
          let lista_items=[]
          bloques.forEach((element, index, array) => {
              lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
          });
          setItems(lista_items);
      }
    },[bloques]);

  useEffect(() =>{

    SetTituloBotonRepetido(false);
    setUrl(props.url===undefined?"":props.url);
    setTitulo(props.titulo===undefined?"":props.titulo);
    setSubtitulo(props.subtitulo===undefined?"":props.subtitulo);
    setBotones(props.botones===undefined?[]:props.botones);
    if(bot_actual.conexion=="instagram"){
      if(props.default_action!==undefined){
        setDefaultAction(props.default_action)
      }else{
        setDefaultAction({type:"web", url:""})
      }
    }

  },[props.url,props.titulo,props.subtitulo]);

    const ModificarURL=(e)=>{
      setUrl(e.target.value);

      props.editar( (prevState)=>{
        prevState[props.index].image_url=e.target.value;
        return prevState;
      });
    }

    const ModificarTitulo=(e)=>{
      setTitulo(e.target.value);

      props.editar( (prevState)=>{
        prevState[props.index].title=e.target.value;
        return prevState;
      });
    }

    const ModificarSubtitulo=(e)=>{
      setSubtitulo(e.target.value);
      props.editar( (prevState)=>{
        prevState[props.index].subtitle=e.target.value;
        return prevState;
      });
    }

    const ModificarDefaultActionUrl=(e)=>{
      // setDefault_action_url(e.target.value);
      var def_action = {
        type:"web_url",
      }
      def_action["url"] = e.target.value
      setDefaultAction(def_action)
      props.editar( (prevState)=>{
        // prevState[props.index].default_action_url=e.target.value;
        prevState[props.index].default_action=def_action;
        return prevState;
      });
    }


    const ModificarTipo=(e)=>{
      setnuevoTipo(e.target.value);
    }

    const ModificarEnviar=(e)=>{
      setEnviar(e.target.value);
    }

    const AgregarBoton=()=>{
      var nuevoTituloTrim = nuevoTitulo.trim()
      setNuevoTitulo(nuevoTituloTrim)
      

      SetTituloBotonRepetido(false);
      setErrorNuevoUrl(false);
      setErrorNuevoTitulo(false);

      if(nuevoTipo==="web_url" && nuevoUrl.length===0)
      {
        setErrorNuevoUrl(true);
        return false;
      }

      if(nuevoTitulo.length===0)
      {
        setErrorNuevoTitulo(true);
        return false;
      }

      /*Buscar si existe un boton con el mismo titulo*/
      const resultado = botones.find( bns => bns.title.toLowerCase()  === nuevoTitulo.toLowerCase());
      if(resultado!=undefined)
      {
        SetTituloBotonRepetido(true);
        return false;
      }



      setBotones((prestate)=>{
        if(nuevoTipo==="web_url")
          {
            prestate.push({
              type:"web_url",
              title:nuevoTituloTrim,
              url:nuevoUrl
            });

            setNuevoTitulo("");
            setNuevoUrl("");
            return prestate;
          }else{
            prestate.push({
              type:"postback",
              title:nuevoTituloTrim,
              enviar_a:enviar==="0"?"":enviar,
              payload:"boton"+props.index,
            });
            setNuevoTitulo("");
            setNuevoUrl("");
            return prestate;
          }
      });
      actualizarBotones?setActualizarBotones(false):setActualizarBotones(true);

      setMensajeCodigoValidacion(-1)


    /*  let temp=JSON.stringify(botones);
      let arreglo=JSON.parse(temp);*/
    /*  if(nuevoTipo==="web_url")
      {
        botones.push({
          type:"web_url",
          title:nuevoTitulo,
          url:nuevoUrl
        });

        setNuevoTitulo("");
        setNuevoUrl("");
      }else{
        botones.push({
          type:"postback",
          title:nuevoTitulo,
          enviar_a:enviar==="0"?"":enviar,
          payload:"boton",
        });
      }
        setNuevoTitulo("");
        setEnviar("0");*/
      //setBotones(arreglo);
    }

    const EliminarModulo=()=>
    {
        props.eliminar({id:props.index});
    }
    const updateContador=(cont)=>{
      setContador(cont)
    }

  return(


         <Box justifyContent="center" key={props.elemento+props.bloque+"nuevo_elemento_carrusel"+props.index}>
            <Box border={2}>
            <div className={classes.margen}>
                <Grid spacing={3} container >


                   <Grid item xs={12}>
                    ----
                    {datos.permisos.abcbot.bloques===true &&
                      <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={EliminarModulo}
                            >
                        <CloseIcon />
                      </IconButton>
                    }
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            value={url}
                            label="URL"
                            variant="outlined"
                            fullWidth
                            onChange={ModificarURL}
                            error={url.length===0}
                            helperText={url.length===0?"El campo es obligatorio":""}
                            disabled={datos.permisos.abcbot.bloques!==true}
                          />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                          value={titulo}
                          label="Titulo"
                          variant="outlined"
                          fullWidth
                          onChange={ModificarTitulo}
                          error={titulo.length===0}
                          helperText={titulo.length===0?"El campo es obligatorio":""}
                          disabled={datos.permisos.abcbot.bloques!==true}
                          />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={subtitulo}
                            label="Subtitulo"
                            variant="outlined"
                            fullWidth
                            multiline
                            onChange={ModificarSubtitulo}
                            disabled={datos.permisos.abcbot.bloques!==true}
                          />
                    </Grid>
                    {bot_actual.conexion=="instagram"?
                      <Grid item xs={12}>
                          <TextField
                              value={defaultAction.url}
                              label="Default action - Web Url"
                              variant="outlined"
                              fullWidth
                              multiline
                              onChange={ModificarDefaultActionUrl}
                              disabled={datos.permisos.abcbot.bloques!==true}
                            />
                      </Grid>
                    :null}
                    
                    <Grid item xs={12}>
                        <Typography>Botones</Typography>
                    </Grid>


              {datos.permisos.abcbot.bloques===true &&
                  <Grid item xs={12} >
                        <Grid spacing={2} container >

                                <Grid item xs={12} sm={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined-quil"
                                        label="Tipo"
                                        value={nuevoTipo}
                                        onChange={ModificarTipo}
                                    >
                                      <MenuItem value={"postback"}>Bloque</MenuItem>
                                      <MenuItem value={"web_url"}>URL</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                      id="outlined-basic"
                                      label="Title"
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                      value={nuevoTitulo}
                                      onChange={
                                        e=>{
                                          /*Para hacerlo mas legible*/
                                          var codigoValidacion = validarTextoBotonesContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                          setMensajeCodigoValidacion(codigoValidacion)
                                          if(codigoValidacion<1){
                                            setNuevoTitulo(e.target.value)
                                          }
                                        }
                                      }
                                      // error={errorNuevoTitulo || tituloBotonRepetido}
                                      // helperText={errorNuevoTitulo?"el campo es obligatorio": tituloBotonRepetido?"El titulo no puede ser repetido ":""}
                                      error={ (mensajeCodigoValidacion != -1) || tituloBotonRepetido} 
                                      helperText={mensajeTextoBotonErrores(mensajeCodigoValidacion)}
                                      inputProps={{
                                        maxLength:maximaCantidadCaracteresEnBotones
                                      }}

                                    />
                                </Grid>


                              {nuevoTipo==="postback"?
                                <Grid item xs={12} sm={4}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined-quil"
                                            label="Enviar"
                                            value={enviar}
                                            onChange={ModificarEnviar}
                                            >
                                            <MenuItem value="0">
                                                <em>None</em>
                                            </MenuItem>
                                            {items}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="outlined-basic"
                                        label="url"
                                        variant="outlined"
                                        fullWidth
                                        value={nuevoUrl}
                                        error={errorNuevoUrl}
                                        helperText={errorNuevoUrl?"el campo es obligatorio":""}
                                        onChange={e=>setNuevoUrl(e.target.value)}
                                        />
                                </Grid>
                                }
                                <Grid item xs={12} sm={1}>
                                  <Tooltip title="Agregar">
                                    <IconButton
                                        disabled={
                                          !(contador<=(maximaCantidadBotonesEnModulo-1))
                                        }
                                        color="primary"
                                        aria-label="delete"
                                        className={classes.margin}
                                        onClick={AgregarBoton}
                                    >
                                        <QueueIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider variant="middle" className={classes.colorDivider}/>
                                </Grid>

                        </Grid>
                    </Grid>
                  }

                      <Botones
                      index={props.index}
                      bloques={props.bloque}
                      elemento={props.elemento}
                      lista={botones}
                      editar={ props.editar}
                      actualizar={actualizarBotones}
                      // setListaBotones={setBotones}
                      setContador={updateContador}
                      // actualizarListaBotonesPadre={actualizarListaBotonesPadre}
                    />



                </Grid>
                </div>
            </Box>
         </Box>

  );

}

export default Modulos;
