import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  Fade,
  TextField as Input
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import google from "../../images/google.svg";

import logo2 from "./logo.png";
import logo_color from "./logo_full.png"

// context
import { useUserDispatch, loginUser ,userForgetPassword} from "../../context/UserContext";
import { receiveToken } from "../../context/UserContext";
import { useThemeState } from "../../context/ThemeContext"
//components
import { Button, Typography } from "../../components/Wrappers";
import Widget from "../../components/Widget";
import config from "../../config";

const getGreeting = () => {
  const d = new Date();
  if (d.getHours() >= 4 && d.getHours() <= 12) {
    return "Good Morning";
  } else if (d.getHours() >= 13 && d.getHours() <= 16) {
    return "Good Day";
  } else if (d.getHours() >= 17 && d.getHours() <= 23) {
    return "Good Evening";
  } else {
    return "Good Night";
  }
};

function Login(props) {
  var classes = useStyles();
  const tema_actual = useThemeState();
  // global
  var userDispatch = useUserDispatch();

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");
    if (token) {
      receiveToken(token, userDispatch);
    }
  }, []);

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  var [errordetail, setErrordetail] = useState(null);
  var [forgetPassword,setForgetPassword]=useState(false);
  var [isLoadingForget, setIsLoadingForget] = useState(false);
  var [succsesForget, setSuccsesForget] = useState(false);


  function mostrarforgetPassword()
  {
      setForgetPassword(true);
      setError(false);
      setSuccsesForget(false);

  }

  function ocultarforgetPassword()
  {
      setForgetPassword(false);
      setError(false);
      setSuccsesForget(false);
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={tema_actual.automataconf.imagenes.login === undefined ? logo2 : tema_actual.automataconf.imagenes.login}
             alt="logo"
             className={tema_actual.automataconf.config === "logo" ? classes.logotypeImage : classes.logotypeImagefull}
        />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>

            <React.Fragment>

              <div className={classes.greetingtituloContent}>
                <div className={classes.logotypeContainerColor}>
                  <img src={tema_actual.automataconf.imagenes.loginmovil === undefined ? logo_color : tema_actual.automataconf.imagenes.loginmovil}
                    className={classes.logotypeImg}
                    alt="logo"
                    className={classes.logotypeImageColor}
                  />
                </div>
                <Typography variant="h1" className={classes.greetingtitulo}>
                  Login
                </Typography>
              </div>

              <div id="form-login">
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}></Typography>
                <div className={classes.formDivider} />
              </div>
              <Typography variant="h5" className={classes.greetingtitulo}
                  style={
                    forgetPassword ? { display: "inline-block" } : { display: "none" }
                    }>
                  Restaurar contraseña
                </Typography>
              <Fade
                in={error}
                style={
                  !error ? { display: "none" } : { display: "inline-block" }
                }
              >
                <Typography  className={classes.errorMessage}>
                  Error:{errordetail}
                </Typography>
              </Fade>
              <Input
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input
                  }
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <Input
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input
                  }
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
                style={
                  forgetPassword ? { display: "none" } : { display: "inline-block" }
                  }
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                        setErrordetail
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    style={
                      forgetPassword ? { display: "none" } : { display: "inline-block" }
                      }
                    >

                    Login
                  </Button>
                )}




                {isLoadingForget ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0
                    }
                    onClick={() =>
                      userForgetPassword(
                        loginValue,
                        props.history,
                        setIsLoadingForget,
                        setError,
                        setErrordetail,
                        setSuccsesForget
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    style={
                      forgetPassword && !succsesForget ? { display: "inline-block" } : { display: "none" }
                      }
                    >

                    Restaurar
                  </Button>
                )}

                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={mostrarforgetPassword}
                  style={
                    forgetPassword ? { display: "none" } : { display: "inline-block" }
                    }
                  >

                  Forgot Password?

                </Button>

                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={ocultarforgetPassword}
                  style={
                    forgetPassword ? { display: "inline-block" } : { display: "none" }
                    }>

                  Regresar

                </Button>
              </div>
              <Typography className={classes.greetingtitulo}
                  style={
                    succsesForget ? { display: "inline-block" } : { display: "none" }
                    }>
                  Se a enviado un correo a {loginValue} con las instrucciones para restaurar su contraseña.
                </Typography>

              </div>


            </React.Fragment>







        </div>

      </div>
    </Grid>
  );
}

export default withRouter(Login);
