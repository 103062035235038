
export const mensajes_chat='https://bots.automatadg.com/servicio/conexion';

export const mensaje_whatsapp='https://bots.automatadg.com/whatsapp/conexion';
export const mensaje_facebook='https://bots.automatadg.com/conexion/webhook';
export const mensaje_telegram="https://bots.automatadg.com/telegram/conexion";
export const mensaje_instagram='https://bots.automatadg.com/instagram/conexion';
export const mensaje_webchat='https://bots.automatadg.com/webchat/webhook';

/*
export const mensajes_chat='https://dev.automatadg.com/servicio/conexion';

export const mensaje_whatsapp='https://dev.automatadg.com/whatsapp/conexion';
export const mensaje_facebook='https://dev.automatadg.com/conexion/webhook';
export const mensaje_telegram="https://dev.automatadg.com/telegram/conexion"
*/

export const cargando_info =
  [
   {
     domain: "souvenirsgt.web.app",
     url : "https://i.imgur.com/ONcxiTP.png",
     color: "#99182c"
   },
   {
     domain: "avi.souvenirs.com.gt",
     url : "https://i.imgur.com/ONcxiTP.png",
     color: "#99182c"
   },
   {
     domain: "localhost",
     url : "https://i.imgur.com/Rv36jTu.png",
     color: "#99182c"
   },
   {
     domain: "registro-mercantil.web.app",
     url : "https://i.imgur.com/Rv36jTu.png",
     color: "#99182c"
   },
   {
     domain: "consolbot.registromercantil.gob.gt",
     url : "https://i.imgur.com/Rv36jTu.png",
     color: "#99182c"
   },
   {
     domain: "automatadg-pruebas.web.app",
     url : "https://i.imgur.com/7MvLrQK.png",
     color: "#99182c"
   }
 ];


export const cargando_tema =
  [
   {
     domain: "souvenirsgt.web.app",
     url : "https://i.imgur.com/OvhTy3a.png",
     color: "#99182c"
   },
   {
     domain: "avi.souvenirs.com.gt",
     url : "https://i.imgur.com/OvhTy3a.png",
     color: "#99182c"
   },
   {
     domain: "localhost",
     url : "https://i.imgur.com/yyU2t6x.png",
     color: "#99182c"
   },
   {
     domain: "registro-mercantil.web.app/",
     url : "https://i.imgur.com/yyU2t6x.png",
     color: "#99182c"
   },
   {
     domain: "consolbot.registromercantil.gob.gt",
     url : "https://i.imgur.com/yyU2t6x.png",
     color: "#99182c"
   },
   {
     domain: "automatadg-pruebas.web.app",
     url : "https://i.imgur.com/hLdxJjL.png",
     color: "#99182c"
   },
 ];
