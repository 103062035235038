import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import {
    Grid,
    TextField as Input,
  } from "@material-ui/core";
import { Button, Typography,Avatar } from "../../components/Wrappers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useUserState,userNewPassword} from "../../context/UserContext";
import {db,fecha_hora} from "../../services/firebase";
import MUIDataTable from "mui-datatables";
import SendIcon from '@material-ui/icons/Send';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { useTheme } from "@material-ui/styles";
import ReactApexChart from 'react-apexcharts'
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    List,
    Box,
} from '@material-ui/core'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Chip from '@material-ui/core/Chip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import ChatCenter from './componentes/ChatCenter';
import FaceIcon from '@material-ui/icons/Face';
import FacebookIcon from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';


function OrdenesCompra ()
{
    var classes = useStyles();
    var theme = useTheme();
    var { datos,user } = useUserState();


    const [nombrebot,setNombrebot]=React.useState("");
    const [listabot,setListabot]=React.useState([]);
    const [progreso,setProgerso] = React.useState(false);
    const [listaOrdenes,setListaordenes] = React.useState([]);
    const [itemOrden,setItemOrden] = React.useState("");
    const [productos,setProductos] = React.useState([]);
    const [jsonOrdenes,setJsonOrdenes] = React.useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [estadoActual, setEstadoActual] = React.useState('active');

    const [totalItemActivos,setTotalItemActivos]=React.useState(0)
    const [totalItempProcesados,setTotalItemProcesados]=React.useState(0)
    const [totalItemCancelados,setTotalItemCancelados]=React.useState(0)

    const [fbname,setFbname]=React.useState('');
    const[cambiarEstado,setCambiarEstado]= React.useState("");
    function compare(a, b) {

        let aa=0;
        let bb=0;

        if (a.estado==="active"){aa=3;}
        else if (a.estado==="succesful"){aa=2;}
        else if (a.estado==="cancel"){aa=1;}

        if (b.estado==="active"){bb=3;}
        else if (b.estado==="succesful"){bb=2;}
        else if (b.estado==="cancel"){bb=1;}

        //a es menor que b según criterio de ordenamiento
        if (bb<aa) {
          return -1;
        }
        //a es mayor que b según criterio de ordenamiento
        if (bb>aa) {
          return 1;
        }
        // a debe ser igual b
        return 0;
    }


    //Obtener informacion de los bots
    useEffect(() =>{

        let conexion_reporte=db.ref("bots/"+datos.id_empresa).orderByChild("nombre").on("child_added",function(snapshot){
            var lista = snapshot.val();

            if(lista.conexiones.facebook){
               setListabot( (prevState)=> {
                prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);
                return prevState;
               });
            }

        });

        return ()=>{
            db.ref("bots/"+datos.id_empresa).off("child_added",conexion_reporte);
          }
    },[]);


    var camibarBot = (event)=> {
        setNombrebot(event.target.value);
        setProductos("");
        setItemOrden("");



    };


    useEffect(() =>{

        if(nombrebot!=="")
        {

            db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor).orderByChild("estado").equalTo("active").on('value',(snapshot)=>{
                setTotalItemActivos(snapshot.numChildren());
            });

            db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor).orderByChild("estado").equalTo("cancel").on('value',(snapshot)=>{
                setTotalItemCancelados(snapshot.numChildren());
            });

            db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor).orderByChild("estado").equalTo("succesful").on('value',(snapshot)=>{
                setTotalItemProcesados(snapshot.numChildren());

            });

            setItemOrden("");
            setListaordenes("");

            let ref="";
            if(estadoActual==="active"){ref= db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor).orderByChild("estado").equalTo("active");}
            if(estadoActual==="cancel"){ref= db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor).orderByChild("estado").equalTo("cancel");}
            if(estadoActual==="succesful"){ref= db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor).orderByChild("estado").equalTo("succesful");}

           ref.on('value',(snapshot)=>{
                let lista_ordenes=[];
                snapshot.forEach(function(data){
                    lista_ordenes.push(
                        <ListItem alignItems="flex-start" button key={data.key+'fbau'}
                            onClick={(event, index) =>{
                                  setItemOrden(data.key);
                                }} >
                            <ListItemAvatar>
                                <ShoppingCartIcon/>
                            </ListItemAvatar>
                            <ListItemText className={classes.listaChatTitulo} primary={"id:"+data.key} secondary={
                            <React.Fragment>
                              <Typography component="span"
                                 className={classes.usuarioActualSubTitulo} >
                                {"responsable: "+data.val().responsable}<br/>
                                {data.val().fecha_estado===""?"":"cambio de estado: "+new Date(data.val().fecha_estado).toLocaleString('es-MX')}
                                <br/>{new Date(data.val().fecha_compra).toLocaleString('es-MX')}
                              </Typography>
                            </React.Fragment>} />
                            <ListItemSecondaryAction>
                            <Chip size="small" label={
                                    data.val().estado==="cancel"? "cancelado":
                                    data.val().estado==="active"? "activo":
                                            "completado"

                            }
                                className={
                                    data.val().estado==="cancel"? classes.cancelado:
                                    data.val().estado==="active"? classes.activo:
                                            classes.completado

                            } />
                            </ListItemSecondaryAction>
                        </ListItem>);
                });
                setListaordenes(lista_ordenes.length===0?"":lista_ordenes);
                setJsonOrdenes(lista_ordenes.length===0?"":snapshot.val());

            });
        }

        return ()=>{
            db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor);
          }

      },[nombrebot,cambiarEstado]);


      useEffect(() =>{
        if(itemOrden!=="")
        {
            db.ref('cliente_chat/'+datos.id_empresa+'/facebook/'+nombrebot.valor+"/"+jsonOrdenes[itemOrden].fbid+"/atributos/fb_username").once('value',function(snapshot){
                setFbname(snapshot.val());
            });
            let productos=[];
            for( var key in jsonOrdenes[itemOrden].articulos){

                var pedido=[];
               // console.log("articulos",jsonOrdenes[itemOrden.id_user][itemOrden.id_orden].articulos[key]);

                for(var keyA in jsonOrdenes[itemOrden].articulos[key]){
                    //console.log("detalle",jsonOrdenes[itemOrden.id_user][itemOrden.id_orden].articulos[key][keyA]);
                   // pedido+=keyA+":"+jsonOrdenes[itemOrden.id_user][itemOrden.id_orden].articulos[key][keyA]+"/n";
                   //pedido.push( <Chip label={keyA+":"+jsonOrdenes[itemOrden.id_user][itemOrden.id_orden].articulos[key][keyA]} color="primary" />);
                   pedido.push( <ListItem key={"roducto"+keyA}>
                                      <ListItemText primary={keyA+":"+jsonOrdenes[itemOrden].articulos[key][keyA]} />
                                </ListItem>);
                }
                let lista_pedido=<List>{pedido}</List>
                productos.push(
                <div key={"listaProducto"+key}>
                    <ListItem>
                        <ListItemIcon>
                        <InboxIcon />
                        </ListItemIcon>
                            {lista_pedido}
                    </ListItem>
                    <Divider />
                </div>

                );
            }

            setProductos(productos);
        }
      },[itemOrden]);

    const Cancelar=()=>{
        setItemOrden("");
        db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor+"/"+itemOrden).
        update({estado:"cancel",
                fecha_estado:fecha_hora.database.ServerValue.TIMESTAMP,
                responsable:datos.nombre,
                responsable_id:user.id
               });
       db.ref('cliente_chat/'+datos.id_empresa+'/facebook/'+nombrebot.valor+'/'+jsonOrdenes[itemOrden].fbid+'/atributos').
       update({
         estado_orden:"cancel"
       });
    }

    const Procesar=()=>{
      setItemOrden("");
       db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor+"/"+itemOrden).
        update({estado:"succesful",
                fecha_estado:fecha_hora.database.ServerValue.TIMESTAMP,
                responsable:datos.nombre,
                responsable_id:user.id
               });
       db.ref('cliente_chat/'+datos.id_empresa+'/facebook/'+nombrebot.valor+'/'+jsonOrdenes[itemOrden].fbid+'/atributos').
       update({
         estado_orden:"succesful"
       });

    }

    const Activar=()=>{
        setItemOrden("");
        db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor+"/"+itemOrden).
        update({estado:"active",
                fecha_estado:fecha_hora.database.ServerValue.TIMESTAMP,
                responsable:datos.nombre,
                responsable_id:user.id
               });
       db.ref('cliente_chat/'+datos.id_empresa+'/facebook/'+nombrebot.valor+'/'+jsonOrdenes[itemOrden].fbid+'/atributos').
       update({
         estado_orden:"active"
       });
    }

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null && event.type==="click") {
            db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+nombrebot.valor).off();
            setEstadoActual(newAlignment);
            setCambiarEstado(newAlignment);
        }
    };


   return (

        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Widget
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu
                        title={'Generar Reporte'}
                >
                        <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                            <Grid item xs={12}  >
                                <Typography className={classes.LeyendaPorcentaje}>Seleccione el bot de Facebook</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                            <Grid item xs={12}  >
                                <FormControl variant="outlined" className={classes.centrar}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Age"
                                        value={nombrebot}
                                        onChange={camibarBot}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                {listabot}
                                    </Select>
                                </FormControl>

                            </Grid>
                        </Grid>

                        {  progreso &&  <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                            <Grid item xs={12}  >
                                <FormControl variant="outlined" className={classes.centrar}>
                                    <CircularProgress />
                                </FormControl>
                            </Grid>
                        </Grid>}

                </Widget>
            </Grid>
            <Grid item xs={12} md={12}>
                <Widget
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    disableWidgetMenu
                >
                      <Grid container spacing={3}  alignItems="flex-start" justify={"space-between"} direction={'row'}>
                        <Grid item xs={12} md={6} >
                        <Chip size="small" label={"Activos:"+totalItemActivos}
                                className={classes.activo} />
                         <Chip size="small" label={"Procesados:"+totalItempProcesados}
                                className={ classes.completado} />
                        <Chip size="small" label={"Cancelados:"+totalItemCancelados}
                                className={ classes.cancelado} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}  alignItems="flex-start" justify={"space-between"} direction={'row'}>
                        <Grid item xs={12} md={6} >
                    <ToggleButtonGroup
                            value={estadoActual}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="Compras"
                            >
                            <ToggleButton value="active" aria-label="left aligned">
                                <LibraryAddIcon/> Activos
                            </ToggleButton>
                            <ToggleButton value="succesful" aria-label="centered">
                                <LibraryAddCheckIcon/>Procesados
                            </ToggleButton>
                            <ToggleButton value="cancel" aria-label="right aligned">
                                <DeleteForeverIcon/>Cancelados
                            </ToggleButton>
                    </ToggleButtonGroup>
                            <List className={classes.rootlist}>
                                {listaOrdenes}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}  >
                     {  itemOrden!=="" &&  jsonOrdenes!=="" &&  jsonOrdenes[itemOrden]!==undefined &&
                            <ListItem  >
                                <ListItemAvatar>
                                    <ShoppingCartIcon/>
                                </ListItemAvatar>
                                <ListItemText className={classes.listaChatTitulo} primary={itemOrden} secondary={
                                <React.Fragment>
                                  <Typography component="span"
                                     className={classes.usuarioActualSubTitulo} >
                                    {"responsable: "+jsonOrdenes[itemOrden].responsable}<br/>
                                    {jsonOrdenes[itemOrden].fecha_estado==="" ?"" :"cambio de estado: "+new Date(jsonOrdenes[itemOrden].fecha_estado).toLocaleString('es-MX')}
                                    <br/>{"fecha orden:"+new Date(jsonOrdenes[itemOrden].fecha_compra).toLocaleString('es-MX')}<br/>
                                  </Typography>
                                  <Typography component="span" className={classes.fbname}>
                                    {"usuario facebook: "+fbname}
                                  </Typography>
                                </React.Fragment>} />
                                <ListItemSecondaryAction>

                                <Chip size="small" label={
                                        jsonOrdenes[itemOrden].estado==="cancel"? "cancelado":
                                        jsonOrdenes[itemOrden].estado==="active"? "activo":
                                            "completado"

                                }
                                    className={
                                        jsonOrdenes[itemOrden].estado==="cancel"? classes.cancelado:
                                        jsonOrdenes[itemOrden].estado==="active"? classes.activo:
                                            classes.completado

                                } />
                                </ListItemSecondaryAction>
                            </ListItem>}

                            {itemOrden!=="" && jsonOrdenes!=="" &&
                                <List className={classes.rootlist}>
                                    {productos}
                                </List>
                            }
                      {jsonOrdenes!=="" && itemOrden !=="" && jsonOrdenes[itemOrden]!==undefined &&
                        <ChatCenter
                        fbid={jsonOrdenes[itemOrden].fbid}
                        nombre_bot={nombrebot.valor}
                        mostrar_procesar={jsonOrdenes[itemOrden].estado==="active" }
                        mostrar_cancelar={(jsonOrdenes[itemOrden].estado==="active" || jsonOrdenes[itemOrden].estado==="succesful") }
                        mostrar_activar={jsonOrdenes[itemOrden].estado==="succesful" }
                        procesar={Procesar}
                        cancelar={Cancelar}
                        activar={Activar}
                        />

                      /*jsonOrdenes!=="" && itemOrden !=="" &&
                           <Grid container  alignItems={"center"} justify={"center"} direction={'row'}>
                                <Grid item xs={12}>
                                 { (jsonOrdenes[itemOrden].estado==="active" || jsonOrdenes[itemOrden].estado==="succesful" )  &&
                                    <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={Cancelar}
                                    startIcon={<DeleteIcon />}
                                    >
                                        Cancelar
                                    </Button>  }
                                   {jsonOrdenes[itemOrden].estado==="active" &&
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={Procesar}
                                    startIcon={<SendIcon />}
                                    >
                                        Procesar
                                    </Button>}
                                </Grid>
                        </Grid>*/}
                        </Grid>
                    </Grid>
                </Widget>
            </Grid>
            {/*
            jsonOrdenes!=="" && itemOrden!=="" && nombrebot!=="" &&
            <ChatCenter
                abrir={abrir_modal}
                cerrar={cerrar_modal}
                fbid={jsonOrdenes[itemOrden].fbid}
                nombre_bot={nombrebot.valor}
                estado={open}
            />*/}

        </Grid>
   );

}



export default OrdenesCompra;
