import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon
} from "@material-ui/icons";
import classNames from "classnames";
import Notification from "../Notification/Notification";
import BusinessIcon from '@material-ui/icons/Business';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// styles
import useStyles from "./styles";
import logo from "./logo.png"

import { useThemeState } from "../../context/ThemeContext"

// components
import { Badge, Typography, Avatar,Link } from "../Wrappers/Wrappers";

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';

import {codigo_funte} from '../../services/firebase'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from "../../context/LayoutContext";
import { useUserDispatch, signOut ,useUserState} from "../../context/UserContext";
import {storage,db,fecha_hora} from "../../services/firebase"
const messages = [
/*  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32"
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18"
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15"
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09"
  }*/
];

/*const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ..."
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification"
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today"
  }
];
*/
export default function Header(props) {
  var classes = useStyles();
  var theme = useTheme();

  const tema_actual = useThemeState();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var { user,datos } = useUserState();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  const [isSmall, setSmall] = useState(false);
  const [notifications,setNotifications]= React.useState([]);

  var [avatarImagen, setAvatarImagen] = useState('');

  var [cantidadNotificaciones,setCantidadNotificaciones]= React.useState(0);


  useEffect(() =>{
      //nombre de la empresa
    let isSubscribed = true;
    var id_empresa='';
    if(localStorage.getItem('userData'))
    {
        let datosUsusario =JSON.parse(localStorage.getItem('userData'));
        id_empresa=datosUsusario.id_empresa;
    }

    if(localStorage.getItem('theme')==='dark')
    {
        var id_empresa=id_empresa+'/dark.png';
    }else{
        var id_empresa=id_empresa+'/icono.png';
    }

    var storageRef=storage.ref('empresas/'+id_empresa);
    storageRef.getDownloadURL().then(function(url) {
        if(isSubscribed){
        setAvatarImagen(url);
        }
    }).catch(function(error) {
      console.log("ërror al descargar imagen de perfil"+error)
    });

    var escuchador_contadores=db.ref('usuario_bot/'+datos.id_empresa+'/informacion/contrato').on('value',function(snapshot) {
        var contrato=snapshot.val();

        if(contrato && contrato.interacciones!== '' && contrato.conteo_interaccion >= contrato.interacciones)
        {
          setCantidadNotificaciones( (prevState) =>{let cantidad=prevState+1;return cantidad})
          setNotifications( (prevState) => {
            let num = prevState.findIndex(element => element.id === "1")
            if(num === -1)
            {
              prevState.unshift({ id: "2", tipo:1, color: "warning", titulo:'Cuidado', message: "Se cobrará una tarifa adicional por Interacción"});
              prevState.unshift({ id: "1", tipo:1, color: "warning", titulo:'Cuidado', message: "Las interacciones han superado las del plan"});
            }
            return prevState;
          });
        }else if (contrato && contrato.interacciones!== '' && contrato.conteo_interaccion>=contrato.interacciones-(contrato.interacciones*0.20)){
          setCantidadNotificaciones( (prevState) =>{let cantidad=prevState+1;return cantidad})
          setNotifications( (prevState) => {
            let num = prevState.findIndex(element => element.id === "3")
            if(num === -1){
              prevState.unshift({ id: "3", tipo:1 ,color: "warning", titulo:'Alerta', message: "Las interacciones de su plan están por llegar a su limite"});
            }
            return prevState;
          });

        }
    });

    let empresa_id=datos.id_empresa;
    let usuario_id=user.id;
    let usuario_nombre=datos.nombre;

    var escuchador_notificaciones=db.ref('notificacion_empresa/'+empresa_id+'/creado').orderByChild("create").on('child_added',function(snapshot) {


      db.ref("bots/"+empresa_id+"/"+snapshot.val().bot).once("value").then(function(snap){
        if(snap.val() !== null)
        {
            let nombre_bot=snap.val().nombre;
            setCantidadNotificaciones( (prevState) =>{let cantidad=prevState+1;return cantidad});
            db.ref("cliente_chat/"+empresa_id+"/"+snapshot.val().red+"/"+snapshot.val().bot+"/"+snapshot.val().id).once("value").then(function(valor){

              setNotifications( (prevState) => {
                prevState.push({ id: snapshot.key,
                                 tipo:2, color: "warning",
                                 titulo:nombre_bot ,
                                 red:snapshot.val().red,
                                 fecha:snapshot.val().create,
                                 usuario:snapshot.val().id,
                                 nombre_usuario:valor.val()=== null? "nombre no disponible":
                                                  snapshot.val().red==="facebook"? valor.val().atributos.fb_username:
                                                  snapshot.val().red==="whatsapp"?valor.val().atributos.ws_username:
                                                  valor.val().atributos.tg_usermane ,
                                 texto:snapshot.val().texto,
                                 operador_id:usuario_id,
                                 nombre_operador:usuario_nombre,
                                 empresa_id:empresa_id,
                                 message: "Nuevo usuario de chat center"});
                return prevState;
              });


            });
        }
      });

    });


    var notifiaciones_eliminar=db.ref('notificacion_empresa/'+empresa_id+'/creado').on('child_removed',function(snapshot) {



        setNotifications( (prevState)=>{
          let buscar = prevState.findIndex(item => item.id === snapshot.key);

          if(buscar!=-1)
          {
            prevState.splice(buscar,1);
            if(cantidadNotificaciones>0)
            {

              setCantidadNotificaciones( (prevState) =>{let cantidad=prevState-1;return cantidad});
            }
          }
          return prevState;
        });

    });


    return () => {
      isSubscribed=false;
      db.ref('usuario_bot/'+datos.id_empresa+'/informacion/contadores').off('value',escuchador_contadores);
      db.ref('notificacion_empresa/'+empresa_id+'/creado').off('value',escuchador_notificaciones);
      db.ref('notificacion_empresa/'+empresa_id+'/creado').off('value',notifiaciones_eliminar);
    }
  },[]);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
    setSmall(isSmallScreen);
  }

  const cerrar_session= () =>{

    //console.log("userid1",user.id);
    new Promise((resolve) => {
      db.ref('conectado/'+user.id).update({"en_linea":false},function(error) {
        if (error) {
          // The write failed...
          resolve();
        } else {
          resolve();
        }
      });
    }).then(function() {
      signOut(userDispatch, props.history);
    });

  }

  const CerrarAllNotificacion=()=>{

          console.log("notifications",notifications);
          let copia=notifications.slice();

          copia.forEach((item,index) => {
            console.log('item',item);
            console.log('index',index);
            //db.ref('notificacion_empresa/'+item.empresa_id+'/creado/'+item.id).set(null);


                db.ref('notificacion_empresa/'+item.empresa_id+'/visto/'+item.id).set({
                  bot:item.titulo,
                  create:item.fecha,
                  id:item.usuario,
                  red:item.red,
                  texto:item.texto,
                  fecha_atendido:fecha_hora.database.ServerValue.TIMESTAMP,
                  id_operador:item.operador_id,
                  nombre_operador:item.nombre_operador
                });

                db.ref('notificacion_empresa/'+item.empresa_id+'/creado/'+item.id).set(null);

          });

setNotificationsMenu(null);
  }

function AceptarNotificacion( refVisto,refCreado,item)
{
    return new Promise(function (fulfill, reject){
        console.log("aceptar notificacion",item);
      db.ref(refVisto).set(null);
      db.ref(refCreado).set({
        bot:item.titulo,
        create:item.fecha,
        id:item.usuario,
        red:item.red,
        texto:item.texto,
        fecha_atendido:fecha_hora.database.ServerValue.TIMESTAMP,
        id_operador:item.operador_id,
        nombre_operador:item.nombre_operador
      });
      fulfill();
    });
}

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {(!layoutState.isSidebarOpened && isSmall) ||
          (layoutState.isSidebarOpened && !isSmall) ? (
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          )}
        </IconButton>
        <img src={tema_actual.automataconf.imagenes.header === undefined ? logo : tema_actual.automataconf.imagenes.header} className={classes.logotypeImg}/>
         {codigo_funte=== "avidg-fb168.firebaseapp.com" && <Typography>Conectado a la base Test </Typography>}
        <div className={classes.grow} />
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
          </div>

      </div>
      <Tooltip title="Ayuda" aria-label="add">
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          href={"http://wiki.automatadg.com"}
          target="_blank"
        >
          <LiveHelpIcon/>
        </IconButton>
      </Tooltip>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
            setCantidadNotificaciones(0);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={cantidadNotificaciones}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isMailsUnread ? messages.length : null}
            color="secondary"
          >
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <Avatar
            alt="Robert Cotton"
            src={avatarImagen}
            classes={{ root: classes.headerIcon }}
          />
        </IconButton>
        <Typography
          block
          variant="body2"
          style={{ display: "flex", alignItems: "center", marginLeft: 8 }}
        >
          Hola,&nbsp;
          <Typography variant="body2" weight={"bold"}>
          {user && user.displayName}
          </Typography>
        </Typography>
        {/*
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
                </Menu>*/}
        { notifications.length>0 &&
                <Menu
                  id="notifications-menu"
                  open={Boolean(notificationsMenu)}
                  anchorEl={notificationsMenu}
                  onClose={() => setNotificationsMenu(null)}
                  className={classes.headerMenu}
                  disableAutoFocusItem

                >
                  {notifications.map(notification => (

                  notification.tipo===1 ?

                  <ListItem alignItems="flex-start" key={notification.id}>
                  <ListItemText
                    primary={ notification.titulo}
                    secondary={
                        notification.message
                    }
                  />
                  </ListItem>
                  :
                  <ListItem alignItems="flex-start" key={notification.id+"unico"+notification.red+notification.titulo }>
                  <ListItemAvatar>
                    {
                        notification.red === "facebook" ? <FacebookIcon style={{ color: "#4867aa"}}/> :
                        notification.red === "whatsapp" ? <WhatsAppIcon style={{ color: "#25cd60"}}/> :
                        <TelegramIcon style={{ color: "#279fd3"}} />

                    }
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.titulo}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2">
                        Nuevo usuario:{ notification.nombre_usuario} en el Chatcenter
                        <br/>
                        {new Date(notification.fecha).toLocaleString('es-MX')}
                        <br/>
                        <Button variant="outlined" size="small" color="primary"
                          onClick={(event)=>{
                            db.ref('notificacion_empresa/'+notification.empresa_id+'/creado/'+notification.id).set(null);
                            db.ref('notificacion_empresa/'+notification.empresa_id+'/visto/'+notification.id).set({
                              bot:notification.titulo,
                              create:notification.fecha,
                              id:notification.usuario,
                              red:notification.red,
                              texto:notification.texto,
                              fecha_atendido:fecha_hora.database.ServerValue.TIMESTAMP,
                              id_operador:notification.operador_id,
                              nombre_operador:notification.nombre_operador
                            });
                            setNotificationsMenu(null);
                          }
                          }
                        >
                          Aceptar
                        </Button>
                       </Typography>
                      </React.Fragment>

                    }
                  />
                  </ListItem>


                  ))}
                 <ListItem button  onClick={CerrarAllNotificacion}>
                    <ListItemText primary="Aceptar Todas" />
                  </ListItem>
                  <ListItem button  onClick={() => setNotificationsMenu(null)}>
                    <ListItemText primary="Cerrar" />
                  </ListItem>
                </Menu>
        }
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium" className={classes.colorSecondaryText}>
              {user && user.displayName}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="p"
              className={classes.colorSecondaryText}
            >
              {user && user.email}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} />
              <Link href="#/app/periflUsuario" color="inherit">
                  Mi Perfil
              </Link>
          </MenuItem>
          {/*<MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Tasks
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Messages
            </MenuItem>*/}
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
            >
                <Link href="#" color="inherit" onClick={cerrar_session}>
                  Cerrar Sesión
                </Link>
            </MenuItem>          
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
