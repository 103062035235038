import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { DropzoneArea } from 'material-ui-dropzone';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { db, storage } from "../../services/firebase";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogTema(props) {
  const [open, setOpen] = React.useState(false);

  const [nombre, setNombre ] = React.useState('');
  const [descripcion, setDescripcion] = React.useState('');
  const [primary, setPrimary] = React.useState('');
  const [secondary, setSecondary] = React.useState('');
  const [fondo, setFondo] = React.useState('');
  const [widget, setWidget] = React.useState('');
  const [textop, setTextop] = React.useState('');
  const [textos, setTextos] = React.useState('');

  const [imageLogin, setImageLogin] = React.useState([]);
  const [imageLoginMovil, setImageLoginMovil] = React.useState([]);
  const [imageHead, setImageHead ] = React.useState([]);
  const [imagebot, setImagebot] = React.useState([]);

  const [progesoLogin, setProgesoLogin] = React.useState(0);
  const [progesoLoginMovil, setProgesoLoginMovil] = React.useState(0);
  const [progesoHead, setProgesoHead] = React.useState(0);
  const [progesoBot, setProgesoBot] = React.useState(0);

  const [nombreProgreso, setNombreProgreso] = React.useState(null);
  const [finalizado, setFinalizado] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (!finalizado)
    {
          setOpen(false);
    }

  };

  useEffect(() =>{
    if(open)
    {
        if(props.datos !== null && props.datos !== undefined )
        {
          setNombre(props.datos.nombre);
          setDescripcion(props.datos.descripcion);
          setPrimary(props.datos.tema.primary);
          setSecondary(props.datos.tema.secondary);
          setFondo(props.datos.tema.background.default);
          setWidget(props.datos.tema.background.widget);
          setTextop(props.datos.tema.text.primary);
          setTextos(props.datos.tema.text.secondary);
          setProgesoLogin(0);
          setProgesoLoginMovil(0);
          setProgesoHead(0);
          setProgesoBot(0);
          setFinalizado(false);
          setNombreProgreso(null);
        }
    }
  },[open]);

  const GuardarCambios = () =>{

          console.log("ruta",`/tema/${props.datos.key}`)
          setNombreProgreso('Guardando informacion');
          setFinalizado(true);
          let datosTemas = JSON.parse(JSON.stringify(props.datos));
          datosTemas.key = null;
          datosTemas.nombre = nombre;
          datosTemas.descripcion = descripcion;
          datosTemas.tema.primary = primary;
          datosTemas.tema.secondary = secondary;
          datosTemas.tema.background.default = fondo;
          datosTemas.tema.background.widget = widget;
          datosTemas.tema.text.primary = textop;
          datosTemas.tema.text.secondary = textos;
          console.log("datostema", datosTemas);
          db.ref(`/tema/${props.datos.key}`).set(datosTemas);

          //CargarImagenes(props.datos.key);
          //sin guardar imagens
          setProgesoLogin(100);
          setProgesoLoginMovil(100);
          setProgesoHead(100);
          setProgesoBot(100);
          setFinalizado(false);

  }

  const CargarImagenes = (keyjson) =>{

    //login
    if(imageLogin.length >0 ){
      ProgresoImagenes(setProgesoLogin, imageLogin[0],'login.png',keyjson );
    }else{
      console.log("progreso",(progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4  );
      if( (100+progesoLoginMovil+progesoHead+progesoBot)/4 === 100){
        finalizarUpload();
      }

    }

    //loginmovil
    if(imageLoginMovil.length >0 ){
      ProgresoImagenes(setProgesoLoginMovil, imageLoginMovil[0],'loginMovil.png',keyjson );
    }else{
      setProgesoLoginMovil(100);
        console.log("progreso",(progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4  );
      if( (progesoLogin+100+progesoHead+progesoBot)/4 === 100){
        finalizarUpload();
      }
    }

    //header
    if(imageHead.length >0 ){
      ProgresoImagenes(setProgesoHead, imageHead[0],'head.png',keyjson );
    }else{
      setProgesoHead(100);
        console.log("progreso",(progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4  );
      if( (progesoLogin+progesoLoginMovil+100+progesoBot)/4 === 100){
        finalizarUpload();
      }
    }
    //bot

    if(imagebot.length >0 ){
      ProgresoImagenes(setProgesoBot, imagebot[0],'bot.png',keyjson );
    }else{
      setProgesoBot(100);
        console.log("progreso",(progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4  );
      if( (progesoLogin+progesoLoginMovil+progesoHead+100)/4 === 100){
        finalizarUpload();
      }
    }
  }

  const ProgresoImagenes = (progreso,file, nombre, keyjson) => {

    let loginupload = storage.ref(`/tema/${keyjson}/${nombre}`).put(file);
    loginupload.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progreso(progress);
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log('error', error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        loginupload.snapshot.ref.getDownloadURL().then((downloadURL) => {

          if( (progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4 === 100){
            finalizarUpload();
          }
        });

      }
    );
  }

  const finalizarUpload = () => {
    console.log("progreso",(progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4  );
    setFinalizado(false);
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<EditIcon />}
        onClick={handleClickOpen}
      >
        Editar
      </Button>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Editar Tema
        </DialogTitle>
        <DialogContent dividers>
          {
            nombreProgreso === null ?(

              <Grid container spacing={1}>
                  <Grid item xs={12}>
                      <TextField
                          value={nombre}
                          fullWidth
                          label="Nombre"
                          variant="outlined"
                          onChange={e => setNombre(e.target.value)}
                          error={nombre.length==0}
                          helperText={nombre.length==0?"Campo obligatorio":""}
                        />
                  </Grid>
                  <Grid item xs={12}>
                      <TextField
                          value={descripcion}
                          fullWidth
                          label="Descripcion"
                          variant="outlined"
                          onChange={e => setDescripcion(e.target.value)}
                          error={descripcion.length==0}
                          helperText={descripcion.length==0?"Campo obligatorio":""}
                      />
                  </Grid>

                  <Grid item xs={12}>
                      <Typography variant="h4" display="block" gutterBottom>
                          Colores
                      </Typography>
                  </Grid>

                  <Grid item xs={12} >
                      <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} >
                              <TextField
                                  value={primary}
                                  type={"color"}
                                  fullWidth
                                  id="outlined-basic"
                                  label="Primary"
                                  variant="outlined"
                                  onChange={e => setPrimary(e.target.value)}
                                />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                              <TextField
                                  value={secondary}
                                  type={"color"}
                                  fullWidth
                                  label="Secondary"
                                  variant="outlined"
                                  onChange={e => setSecondary(e.target.value)}
                              />
                          </Grid>
                      </Grid>
                  </Grid>

                  <Grid item xs={12}>
                      <Typography variant="h4" display="block" gutterBottom>
                          Fondo
                      </Typography>
                  </Grid>

                  <Grid item xs={12} >
                      <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} >
                              <TextField
                                  value={fondo}
                                  type={"color"}
                                  fullWidth
                                  label="Fondo"
                                  variant="outlined"
                                  onChange={e => setFondo(e.target.value)}
                                  />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                              <TextField
                                  value={widget}
                                  type={"color"}
                                  fullWidth
                                  label="Widget"
                                  variant="outlined"
                                  onChange={e => setWidget(e.target.value)}
                                  />
                          </Grid>
                      </Grid>
                  </Grid>

                  <Grid item xs={12}>
                      <Typography variant="h4" display="block" gutterBottom>
                          Texto
                      </Typography>
                  </Grid>

                  <Grid item xs={12} >
                      <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} >
                              <TextField
                                  value={textop}
                                  type={"color"}
                                  fullWidth
                                  label="Texto primary"
                                  variant="outlined"
                                  onChange={e => setTextop(e.target.value)}
                              />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                              <TextField
                                  value={textos}
                                  type={"color"}
                                  fullWidth
                                  label="Texto secondary"
                                  variant="outlined"
                                  onChange={e => setTextos(e.target.value)}
                              />
                          </Grid>
                      </Grid>
                  </Grid>

                  <Grid item xs={12}>
                      <Typography variant="h4" display="block" gutterBottom>
                          Imagenes
                      </Typography>
                  </Grid>

                  <Grid container spacing={5}>

                    <Grid item xs={12} >
                        <Grid container spacing={1}>
                        <img width="150" alt="Login" src={props.datos.tema.imagenes.login} />
                {/*        <DropzoneArea
                            maxFileSize={3000000}
                            filesLimit={1}
                            acceptedFiles={['image/png']}
                            dropzoneText={"Seleccione una imagen para el login con tamaño 1501 × 587 px"}
                            value={imageLogin}
                            onChange={(files) => setImageLogin(files)}
                            id="imagen-upload-login"
                            disabled={true}
                        />*/}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} >
                      <Divider />
                    </Grid>

                    <Grid item xs={12} >
                        <Grid container spacing={1}>
                        <img width="250" alt="Login" src={props.datos.tema.imagenes.loginmovil} />
                      {/*  <DropzoneArea
                            maxFileSize={3000000}
                            filesLimit={1}
                            acceptedFiles={['image/png']}
                            dropzoneText={"Seleccione una imagen para el login movil con tamaño 3125 × 1223 px"}
                            value={imageLoginMovil}
                            onChange={(files) => setImageLoginMovil(files)}
                            disabled={true}
                        />*/}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} >
                      <Divider />
                    </Grid>

                    <Grid item xs={12} >
                        <Grid container spacing={1}>
                        <img width="300" alt="Login" src={props.datos.tema.imagenes.header} />
                        {/*<DropzoneArea
                            maxFileSize={3000000}
                            filesLimit={1}
                            acceptedFiles={['image/png']}
                            dropzoneText={"Seleccione una imagen para el head con tamaño 3127 × 448 px"}
                            value={imageHead}
                            onChange={(files) => setImageHead(files)}
                            disabled={true}
                        />*/}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} >
                      <Divider />
                    </Grid>

                    <Grid item xs={12} >
                        <Grid container spacing={1}>
                          <img width="100" alt="Login" src={props.datos.tema.imagenes.bot} />
    {/*                      <DropzoneArea
                              maxFileSize={3000000}
                              filesLimit={1}
                              acceptedFiles={['image/png']}
                              dropzoneText={"Seleccione una imagen para el bot con tamaño 640 x 640 px"}
                              value={imagebot}
                              onChange={(files) => setImagebot(files)}
                              disabled={true}
                          />*/}
                        </Grid>
                    </Grid>

                  </Grid>
              </Grid>

            ) :
              <>
                 <Typography variant="h4" display="block" gutterBottom>
                      {nombreProgreso}
                 </Typography>
                 <LinearProgress variant="determinate" value={(progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4} />
                 {(progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4 === 100 &&
                   <Alert severity="success">Datos actualizados correctamente</Alert>
                 }
               </>
            }



        </DialogContent>
        <DialogActions>
          <Button
              autoFocus
              onClick={GuardarCambios}
              color="primary"
              disabled={
                nombre === '' ||
                descripcion  === '' ||
                primary  === '' ||
                secondary  === '' ||
                fondo  === '' ||
                widget  === '' ||
                textop  === '' ||
                textos  === '' ||
                (imageLogin === [] && props.datos !== undefined && props.datos.tema.imagenes.login !== undefined) ||
                (imageLoginMovil === [] && props.datos !== undefined && props.datos.tema.imagenes.loginmovil !== undefined) ||
                (imageHead === [] && props.datos !== undefined && props.datos.tema.imagenes.header !== undefined) ||
                (imagebot === [] && props.datos !== undefined && props.datos.tema.imagenes.bot !== undefined) ||
                finalizado === true ||
                (progesoLogin+progesoLoginMovil+progesoHead+progesoBot)/4 === 100
              }
          >
            Guardar cambios
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
