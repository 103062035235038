import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import Widget from '../../../components/Widget';
import useStyles from '../styles';
import Chip from '@material-ui/core/Chip';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Button, Typography,Avatar } from "../../../components/Wrappers";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useUserState} from "../../../context/UserContext";
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import imagenAvi from '../../../images/original.png'
import FacebookIcon from '@material-ui/icons/Facebook';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramAppIcon from '@material-ui/icons/Instagram';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import NuevoAsistente from './NuevoAsistente';
import EliminarAsistente from './EliminarAsistente';
import IA from './IA';
import Link from '@material-ui/core/Link';
import LlaveFb from './configBot/llaveFb';
import LlaveWa from './configBot/llaveWa';
import LlaveTg from './configBot/llaveTg';
import LlaveIg from './configBot/llaveIg';
import LlaveWeb from './configBot/llaveWeb';
import { useThemeState } from "../../../context/ThemeContext"

import Iabot from './configBot/iabot'
import LanguageIcon from '@material-ui/icons/Language';

import { useAdminBotDispatch,
         botActual,
         useAdminBotState} from '../contexto/AdminBotContext'


const BotAdmin = (props) => {

    var classes = useStyles();
    const tema_actual = useThemeState();
    var { datos } = useUserState();
    var adminBotDispach=useAdminBotDispatch();
    var {bot_actual} =useAdminBotState();


    const [mostrarSlect,setMostrarSelect]=React.useState(true);
    const [progreso,setProgerso] = React.useState(false);
    const [nombrebot,setNombrebot]=React.useState("");
    const [listabot,setListabot]=React.useState([]);

    /*Facebook*/
    const [menuFacebook, setMenuFacebook] = React.useState(null);
    const  openFacebook= (event) => {
        setMenuFacebook(event.currentTarget);

    };
    const closeFacebook = () => {
        setMenuFacebook(null);
    };

    //Editor de bloques
    const editorJsonRedSocial =(red_social)=>{
        if(red_social[0]=="f"){
            setMenuFacebook(null);
        }else if(red_social[0]=="w"){
            setmenuWhatsApp(null);
        }else if(red_social[0]=="i"){
            setmenuInstagram(null);
        }else if(red_social[0]=="t"){
            setmenuTelegram(null);
        }
    botActual(adminBotDispach,{id:props.id,
                                   nombre:props.datos.nombre,
                                   conexion:red_social
                                  })
    }

    /*Telegram*/
    const [menuTelegram, setmenuTelegram] = React.useState(null);
    const openTelegram = (event) => {
        setmenuTelegram(event.currentTarget);
    };
    const closeTelegram = () => {
        setmenuTelegram(null);
    };
    /*WhatsApp*/
    const [menuWhatsApp, setmenuWhatsApp] = React.useState(null);
    const openWhatsApp = (event) => {
        setmenuWhatsApp(event.currentTarget);
    };
    const closeWhatsApp = () => {
        setmenuWhatsApp(null);
    };
    /*Instagram*/
    const [menuInstagram, setmenuInstagram] = React.useState(null);
    const openInstagram = (event) => {
        setmenuInstagram(event.currentTarget);
    };
    const closeInstagram = () => {
        setmenuInstagram(null);
    };

    /*Instagram*/
    const [menuWebchat, setmenuWebchat] = React.useState(null);
    const openWebchat = (event) => {
        setmenuWebchat(event.currentTarget);
    };
    const closeWebchat = () => {
        setmenuWebchat(null);
    };

    //conexiones dialog
    const [openIAMenuFacebook, setIAMenuFacebook] = React.useState(false);
    const [openConexionWa, setOpenConexionWa] = React.useState(false);
    const [openConexionTe, setOpenConexionTe] = React.useState(false);
    const [openConexionWeb, setOpenConexionWeb] = React.useState(false);
    const [openConexionIns, setOpenConexionIns] = React.useState(false);

    return (
        <Grid item sm={12} md={6}  lg={3}>
             <Widget
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            >

            <Grid container  justify="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography className={classes.textoHeadEmpresa} variant="body2" >
                        ID:{props.id}
                    </Typography>
                    <Typography className={classes.textoHeadEmpresa} variant="body2">
                        Nombre: {props.datos.nombre}
                    </Typography>

                    {props.datos.tipo_imagen==="url" || props.datos.tipo_imagen==="imagen"
                    ?
                      <Avatar
                          alt="Avatar"
                          src={props.datos.url}
                          variant="square"
                          classes={{ root: classes.imagenes }}
                      />
                    :
                      <Avatar
                          alt="Avatar"
                          src={tema_actual.automataconf.imagenes.bot === undefined ? imagenAvi : tema_actual.automataconf.imagenes.bot }
                          variant="square"
                          classes={{ root: classes.imagenes }}
                      />
                    }

                    <Grid container  alignItems="flex-end" >

                        {props.datos.conexiones.facebook==true?
                            <Grid item xs={3}>
                            <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                                 <IconButton
                                        variant="contained"
                                        aria-label="Facebook"
                                        className={classes.colorFB}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={openFacebook}
                                        >
                                     <FacebookIcon fontSize="large" />
                                 </IconButton>
                            </Typography>
                            <Menu
                                id="simple-menu"
                                anchorEl={menuFacebook}
                                keepMounted
                                open={Boolean(menuFacebook)}
                                onClose={closeFacebook}
                                >
                                <MenuItem onClick={ e => setIAMenuFacebook(true)}>Conexión</MenuItem>
                                <MenuItem onClick={()=>{
                                    editorJsonRedSocial("facebook")
                                }}>Mostrar flujo</MenuItem>
                            </Menu>
                        </Grid>
                            :null
                        }
                        {props.datos.conexiones.whatsapp==true?
                        <Grid item xs={3}>
                            <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                                <Tooltip title="Configuración Whatsapp">
                                    <IconButton
                                        aria-label="Whatsapp"
                                        className={classes.colorWa}
                                        variant="contained"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={openWhatsApp}

                                        >
                                        <WhatsAppIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={menuWhatsApp}
                                    keepMounted
                                    open={Boolean(menuWhatsApp)}
                                    onClose={closeWhatsApp}
                                >
                                    <MenuItem onClick={ e => setOpenConexionWa(true)}>Conexión</MenuItem>
                                    <MenuItem onClick={()=>{
                                    editorJsonRedSocial("whatsapp")
                                  }}>Mostrar flujo</MenuItem>
                                </Menu>
                            </Typography>
                        </Grid>
                            :null
                        }
                        {props.datos.conexiones.instagram==true?
                        <Grid item xs={3}>
                            <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                                <Tooltip title="Configuracion Instagram">
                                    <IconButton
                                        aria-label="Instagram"

                                        variant="contained"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={openInstagram}
                                        >
                                        <InstagramAppIcon className={classes.colorIg} fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={menuInstagram}
                                    keepMounted
                                    open={Boolean(menuInstagram)}
                                    onClose={closeInstagram}
                                >
                                        <MenuItem onClick={ e => setOpenConexionIns(true)}>Conexión</MenuItem>
                                        <MenuItem onClick={()=>{
                                        editorJsonRedSocial("instagram")
                                    }}>Mostrar flujo</MenuItem>
                                </Menu>
                        </Typography>
                    </Grid>
                        :null}

                        {props.datos.conexiones.telegram==true?
                        <Grid item xs={3}>
                            <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                                <Tooltip title="Configuracion Telegram">
                                    <IconButton
                                        aria-label="telegram"
                                        className={classes.colorTg}
                                        variant="contained"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={openTelegram}
                                        >
                                        <TelegramIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={menuTelegram}
                                    keepMounted
                                    open={Boolean(menuTelegram)}
                                    onClose={closeTelegram}
                                >
                                        <MenuItem onClick={ e => setOpenConexionTe(true)}>Conexión</MenuItem>
                                    {/*<MenuItem onClick={()=>{
                                            editorJsonRedSocial("telegram")
                                        }}>Mostrar flujo
                                    </MenuItem>*/}
                                    <MenuItem>
                                     <Typography>
                                        <Link href={"#/app/tgbots/"+props.id} >Mostrar flujo</Link>
                                    </Typography>
                                   </MenuItem>
                                </Menu>
                            </Typography>
                        </Grid>
                            :null
                        }

                        {props.datos.conexiones.webchat==true?
                        <Grid item xs={3}>
                            <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                                <Tooltip title="Configuracion webchat">
                                    <IconButton
                                        aria-label="webchat"
                                        className={classes.colorWeb}
                                        variant="contained"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={openWebchat}
                                        >
                                        <LanguageIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={menuWebchat}
                                    keepMounted
                                    open={Boolean(menuWebchat)}
                                    onClose={closeWebchat}
                                >
                                  <MenuItem onClick={ e => setOpenConexionWeb(true)}>Conexión</MenuItem>
                                  <MenuItem onClick={()=>{
                                    editorJsonRedSocial("webchat")
                                      }}>Mostrar flujo
                                  </MenuItem>
                                </Menu>
                            </Typography>
                        </Grid>
                            :null
                        }


                        <Grid item xs={3}>
                          <EliminarAsistente
                              index={props.id}
                              nombre={props.datos.nombre}
                              eliminar={props.eliminar}
                            />
                        </Grid>

                        <Grid item xs={3}>
                          <Iabot id={props.id} />
                        </Grid>

                        <Grid item xs={4} >
                            <NuevoAsistente
                                index={props.id}
                                datos={props.datos}
                                />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Widget>
        <LlaveFb  id={props.id} cerrarMenu={closeFacebook} open={openIAMenuFacebook} cambiarOpen={setIAMenuFacebook}/>
        <LlaveWa  id={props.id} cerrarMenu={closeWhatsApp} open={openConexionWa} cambiarOpen={setOpenConexionWa} />
        <LlaveIg  id={props.id} cerrarMenu={closeInstagram} open={openConexionIns} cambiarOpen={setOpenConexionIns} />
        <LlaveTg  id={props.id} cerrarMenu={closeTelegram} open={openConexionTe} cambiarOpen={setOpenConexionTe}/>
        <LlaveWeb id={props.id} cerrarMenu={closeWebchat} nombre={props.datos.nombre} empresa={datos.id_empresa} open={openConexionWeb} cambiarOpen={setOpenConexionWeb}/>
        </Grid>
    );
};

export default BotAdmin;
