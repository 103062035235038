import React from "react";
import { useThemeState } from "../context/ThemeContext"
import { cargando_tema } from "../services/automata"
import { CircularProgress } from "@material-ui/core";

function RootTema ({ children }) {


        const tema_actual = useThemeState();
        let cargando = cargando_tema.find( element => element.domain === document.domain );

        if(!tema_actual.automataconf.ready)
        {
            return (
              <>{cargando === undefined ? "Loading tema..." :
              <>
              <div style={{textAlign:"center"}}>
                  <img width="45%" src={cargando.url}/>
              </div>
              <div style={{textAlign:"center"}}>
              <CircularProgress />
              </div>
              </>
            }
              </>);
        }else{
            return (<div>{children}</div>);
        }
}
export default RootTema;
