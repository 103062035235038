import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import {db,fecha_hora} from "../../../services/firebase";
import { useUserState} from "../../../context/UserContext";
import useStyles from '../styles';

import {
  Grid
} from "@material-ui/core";
import { Typography,Avatar } from "../../../components/Wrappers";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }



export default function ChatCenter(props) {

  var { datos } = useUserState();
  var classes = useStyles();

  const [mensaje,setMensaje]=React.useState("");
  const [cargando,setCargando]=React.useState(false);

  const Activo=()=>{
    props.activar();
  }

  const Cancelar=()=>{ 
    setCargando(true);
    if(mensaje==="")
    { //enviar sin mensaje
      props.cancelar();
      setCargando(false);
    }else{
      enviarMensaje('cancelar');
    }

  }

  const Procesar=()=>{ 
    setCargando(true);
    if(mensaje==="")
    { props.procesar();
      setCargando(false);
    }else{
      enviarMensaje('procesar');
    }

  }

  const [open, setOpen] = React.useState(false);
  const [error,setError] = React.useState(false);


 

  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpen(false);
  };
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setError(false);
};

  function enviarMensaje(tipo)
  {

    db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+props.nombre_bot+"/"+props.fbid+"/atributos").update({
      chatcenter:true,
      fecha_chatcenter:fecha_hora.database.ServerValue.TIMESTAMP
    }); 

    var formData = new FormData();
    formData.append('bot',props.nombre_bot);
    formData.append('empresa',datos.id_empresa);
    formData.append('mensaje',mensaje);
    formData.append('userid',props.fbid);
    formData.append('red','facebook');

    fetch('https://bots.automatadg.com/servicio/conexion', {
      method: 'POST',                 
      body: formData, // data can be `string` or {object}!      
    }).then((response) => {      
          return response;
        })
        .then((empleados) => {
          //console.log('respuesta',empleados);
          simularMensaje(tipo); 
        }).catch( (error)=>{
          //console.log("error al enviar",error);
          setError(true);
          setCargando(false);
         
        });


  }


  function simularMensaje(tipo){
    
    fetch('https://bots.automatadg.com/conexion/webhook?bot='+props.nombre_bot+'&id='+datos.id_empresa, {
      method: 'POST',                 
      body: JSON.stringify({
        "entry": [
          {
            "messaging": [
              {
                "message": {
                  "mid": datos.id_empresa ,
                  "text": "next_element"
                },
                "sender": {
                  "id": props.fbid
                }
              }
            ]
          }
        ]
      }), // data can be `string` or {object}!      
    }).then((response) => {
      //console.log('response',response);
          return response;
        })
        .then((empleados) => {    
          db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+props.nombre_bot+"/"+props.fbid+"/atributos").update({
            chatcenter:false
          });  
          
          if(tipo==="procesar")
          {
            props.procesar();
          }

          if(tipo==="cancelar")
          {
            props.cancelar();
          }
          
          setOpen(true);
          setCargando(false);
        }).catch( (error)=>{
          setError(true);
          setCargando(false);
        }); 
  
  }

  return (
    <Grid container  alignItems={"center"} justify={"center"} direction={'row'}>
        <Grid item xs={12}>
          {cargando===false && props.mostrar_procesar &&
          <div>
            <Typography>
              Escriba un mensaje si desea enviar un mensaje por medio del chat de facebook al cliente 
            </Typography>
            <TextField                
                  margin="dense"
                  id="mensaje"
                  name="mensaje"
                  autoComplete="new-mensaje"
                  label="Mensaje"        
                  fullWidth
                  rows={2}
                  variant="outlined"    
                  value={mensaje}
                  onChange={e => setMensaje(e.target.value)} 
                  /> 
          </div>  
          } 
         {cargando===false && props.mostrar_cancelar &&
          <Button
          variant="contained"         
          onClick={Cancelar} 
          className={classes.botonCancelar}     
          startIcon={<DeleteIcon />}
          >
             {mensaje===""?"cancelar":"Cancelar y enviar mensaje"}
          </Button>  }                            
        
          {cargando===false && props.mostrar_procesar &&
          <Button
          variant="contained"
          color="primary"  
          onClick={Procesar}        
          startIcon={<SendIcon />}
          >
               {mensaje===""?"procesar":"Procesar y enviar mensaje"}
          </Button>}

          {cargando===false && props.mostrar_activar &&
          <Button
          variant="contained"
          color="primary"  
          onClick={Activo}        
          startIcon={<SendIcon />}
          >
            Activo
          </Button>}


         {cargando && <CircularProgress/>}
        </Grid>      
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
                Procesado correctamente
            </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error">
                Error al enviar el mensaje
            </Alert>
        </Snackbar>

    </Grid>  
  );
}