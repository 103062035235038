import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import { db } from "../../services/firebase";
import ConfirmarDialog from './ConfirmarDialog'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Asignar(props) {
  const [open, setOpen] = React.useState(false);

  const [selectTema, setSelectTema] = React.useState("");
  const [asignarDomain, setAsignarDomain] =  React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const AgregarTema = (e) =>{("");
    db.ref('tema_asignado').push({domain:asignarDomain,tema:selectTema});
    setSelectTema("");
    setAsignarDomain("");
  }

  const EliminarAsignacion = (id) =>{
    db.ref(`tema_asignado/${id}`).set(null);
  }

  return (
    <div>
    <Button variant="contained" color="primary" disableElevation onClick={handleClickOpen}>
      Asignar tema
    </Button>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Asignar tema
        </DialogTitle>
        <DialogContent dividers>

        <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Tema</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Age"
                  value = {selectTema}
                  onChange={e => setSelectTema(e.target.value)}

                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                {props.temas.map((row) => (
                  <MenuItem key={row.key+"keylistatema"} value={row.key}>{row.nombre}</MenuItem>
                ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} >
                <TextField value={asignarDomain} onChange={e => setAsignarDomain(e.target.value)} fullWidth id="outlined-basic" label="Dominio" variant="outlined" />
            </Grid>
            <Grid item xs={12} >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick = {AgregarTema}
                    disabled={ asignarDomain === "" || selectTema=== "" }>
                  Agregar
                </Button>
            </Grid>
            <Grid item xs={12}>
            <List >

            {props.asignados.map((row) => (
              <div key={row.key+"lista asignados"}>
              <ListItem>
                  <ListItemText primary={`Dominio: ${row.domain}`} secondary={`Tema: ${row.tema}`} />
                  <ListItemSecondaryAction>
                  <IconButton aria-label="delete" onClick={ e => EliminarAsignacion(row.key)}>
                      <DeleteIcon style={{color:"red"}} />
                  </IconButton>
                  </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              </div>
            ))}

            </List>
            </Grid>
        </Grid>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
