import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../contexto/AdminBotContext'
import { useUserState} from "../../../context/UserContext";
import {db} from "../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteElementos from './DeleteElementos';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import {Droppable, Draggable} from "react-beautiful-dnd"

/*elementos*/
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import ReplyIcon from '@material-ui/icons/Reply';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ChatIcon from '@material-ui/icons/Chat';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import HttpIcon from '@material-ui/icons/Http';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LanguageIcon from '@material-ui/icons/Language';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ForumIcon from '@material-ui/icons/Forum';

import Box from '@material-ui/core/Box';

/*Elementos facebook*/
import {
        Texto,
        Imagen,
        TextoRapido,
        PlantillaBoton,
        ChatCenter,
        Carrusel,
        WebService,
        CompraWeb,
        OrdenCompra,
        WebView,
        NoElement,
    } from './elementos'
/*Elementos facebook*/
import {
        Whatsapp_Texto,
        Whatsapp_Menu,
        Whatsapp_WebService,
        Whatsapp_ChatCenter,
        Whatsapp_Archivo

} from './elementos/elementos_whatsapp'

import {
  Instagram_Texto,
  Instagram_TextoRapido,
  Instagram_Chatcenter,
  Instagram_Carrusel,
  Instagram_Archivo,
  Instagram_WebService,
  Instagram_Planificador,
  Instagram_Historia,

} from './elementos/elementos_instagram'

/*Elementos webchat*/
import {
  Webchat_ChatCenter,
  WebChat_TextoRapido,
}from './elementos/elementos_webchat'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    },
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   borderElemento:{
     border: `1px solid ${theme.palette.divider}`,
     borderRadius: theme.shape.borderRadius,
     backgroundColor: theme.palette.background.paper,
     color: theme.palette.text.secondary,
   },
   borderItem:{
      /*boxShadow: '1px 1px 5px #999',*/
      paddingTop: '15px',
      paddingBottom:'15px',
      marginBottom:'10px',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
   }
  }),
);


export default function NuevoElemento(props){
    var classes = useStyles();
    var {bot_actual,bloques,bloque_actual,lista_bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [nuevoElemento,setNuevoElemento] = React.useState("");

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };




    return(
        <React.Fragment>
        <Grid spacing={3} container >


          <Grid item xs={12}>
          {datos.permisos.abcbot.bloques===true &&  <Typography variant="h4">Nuevo elemento</Typography>}
          </Grid>
          <Grid item xs={12}>
            {datos.permisos.abcbot.bloques===true && <Typography variant="body2">Seleccione el elemento y arrastrelo a la lista de elementos de un bloque para crear un nuevo elemento</Typography>}
          </Grid>

          <Grid item xs={12}>
            {datos.permisos.abcbot.bloques===true && bot_actual.conexion=="facebook"?
            elementos_facebook(NuevoElemento,classes)
            :null}
            {datos.permisos.abcbot.bloques===true && bot_actual.conexion=="whatsapp"?
            elementos_whatsapp(NuevoElemento,classes)
            :null}
            {datos.permisos.abcbot.bloques===true && bot_actual.conexion=="instagram"?
            elementos_instagram(NuevoElemento,classes)
            :null}
            {datos.permisos.abcbot.bloques===true && bot_actual.conexion=="webchat"?
            elementos_webchat(NuevoElemento,classes)
            :null}
            {datos.permisos.abcbot.bloques===true && bot_actual.conexion=="telegram"?
            elementos_telegram(NuevoElemento,classes)
            :null}

          </Grid>



          </Grid>


      {/*      <Tooltip title="Nuevo Elemento">
              <IconButton
                  color="secondary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClickOpen}
                  disabled={bloque_actual===""?true: datos.permisos.abcbot.bloques===true?false:true}
                  >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>

            {nuevoElemento}

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                <DialogTitle id="form-dialog-title">Nuevo Elemento <AddBoxIcon/> </DialogTitle>
                <DialogContent dividers>


                <Grid spacing={3} container >


                  <Grid item xs={12}>
                    <Typography>Seleccione el elemento</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {bot_actual.conexion=="facebook"?
                    elementos_facebook(NuevoElemento)
                    :null}
                    {bot_actual.conexion=="whatsapp"?
                    elementos_whatsapp(NuevoElemento)
                    :null}
                    {bot_actual.conexion=="instagram"?
                    elementos_instagram(NuevoElemento)
                    :null}

                  </Grid>



                  </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>*/}

        </React.Fragment>
       );

}

function elementos_facebook(NuevoElemento,classes){

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#f5f9fa" : "",
  });

  return(

    <Droppable droppableId="Drop_Elementos_facebook" >
    {
      (droppableProvided,snapshot)=>(
        <List component="nav"
                          aria-label="main mailbox folders"
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          >

            <Draggable draggableId={"componenteFacebook-texto"} index={0} >
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                       {...draggableProvided.draggableProps}
                                       ref={draggableProvided.innerRef}
                                       {...draggableProvided.dragHandleProps}
                                    >
                                     <ListItemIcon>
                                       <AssignmentRoundedIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Texto" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-archivo"} index={1}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                >
                                     <ListItemIcon>
                                       <ImageRoundedIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Archivo" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-texto_rapido"} index={2}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <ReplyIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Texto Rapido" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-texto_boton"} index={3}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <ListAltIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Plantilla con boton" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-chatcenter"} index={4}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                   >
                                     <ListItemIcon>
                                       <ChatIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="ChatCenter" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-carrusel"} index={5}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <ViewCarouselIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Carrusel" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-webservice"} index={6}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <HttpIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="WebService" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-webview_detalle"} index={7}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <ShoppingCartIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Detalle" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteFacebook-webview_showpage"} index={8}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <LanguageIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="WebView" />
                                   </ListItem>
                                )}
            </Draggable>
            {droppableProvided.placeholder}
        </List>
      )
    }
    </Droppable>


  )
}

function elementos_whatsapp(NuevoElemento,classes){

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#f5f9fa" : "",
  });

  return(
    <Droppable droppableId="Drop_Elementos_whatsapp" >
    {
      (droppableProvided,snapshot)=>(
        <List component="nav"
                          aria-label="main mailbox folders"
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          >
                          <Draggable draggableId={"componenteWhatsapp-texto"} index={0}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <AssignmentRoundedIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Texto" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteWhatsapp-menu"} index={1}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ReplyIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Menú" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteWhatsapp-webservice"} index={2}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <HttpIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="WebService" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteWhatsapp-chatcenter"} index={3}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ChatIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="ChatCenter" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteWhatsapp-archivo"} index={4}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ImageRoundedIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Archivo" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteWhatsapp-texto_rapido"} index={5}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ReplyIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Texto rapido" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteWhatsapp-chatcenter2"} index={6}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ForumIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="ChatCenter2" />
                                                </ListItem>
                                              )}
                          </Draggable>


            {droppableProvided.placeholder}
        </List>
      )}
    </Droppable>

  )
}

function elementos_instagram(NuevoElemento,classes){

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#f5f9fa" : "",
  });

  return(
    <Droppable droppableId="Drop_Elementos_Instagram" >
    {
      (droppableProvided,snapshot)=>(
        <List component="nav"
                          aria-label="main mailbox folders"
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          >
                          <Draggable draggableId={"componenteInstagram-texto"} index={0}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <AssignmentRoundedIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Texto" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteInstagram-texto_rapido"} index={1}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ReplyIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Texto Rapido" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteInstagram-chatcenter"} index={2}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ChatIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="ChatCenter" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteInstagram-carrusel"} index={3}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ViewCarouselIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Carrusel" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteInstagram-archivo"} index={4}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <ImageRoundedIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Archivo" />
                                                </ListItem>
                                              )}
                          </Draggable>
                          <Draggable draggableId={"componenteInstagram-WebService"} index={5}>
                                               {(draggableProvided)=>(
                                                <ListItem className={classes.borderItem}
                                                     {...draggableProvided.draggableProps}
                                                     ref={draggableProvided.innerRef}
                                                     {...draggableProvided.dragHandleProps}
                                                  >
                                                  <ListItemIcon>
                                                    <HttpIcon />
                                                  </ListItemIcon>
                                                  <ListItemText primary="WebService" />
                                                </ListItem>
                                              )}
                          </Draggable>

          {droppableProvided.placeholder}
      </List>
    )}
    </Droppable>

  )
}



function elementos_webchat(NuevoElemento,classes){

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#f5f9fa" : "",
  });

  return(

    <Droppable droppableId="Drop_Elementos_webchat" >
    {
      (droppableProvided,snapshot)=>(
        <List component="nav"
                          aria-label="main mailbox folders"
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          >

            <Draggable draggableId={"componenteWebchat-texto"} index={0}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                       {...draggableProvided.draggableProps}
                                       ref={draggableProvided.innerRef}
                                       {...draggableProvided.dragHandleProps}
                                    >
                                     <ListItemIcon>
                                       <AssignmentRoundedIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Texto" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteWebchat-archivo"} index={1}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                >
                                     <ListItemIcon>
                                       <ImageRoundedIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Archivo" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteWebchat-texto_rapido"} index={2}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <ReplyIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Texto Rapido" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteWebchat-chatcenter"} index={3}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                   >
                                     <ListItemIcon>
                                       <ChatIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="ChatCenter" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteWebchat-webservice"} index={4}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <HttpIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="WebService" />
                                   </ListItem>
                                )}
            </Draggable>
            {droppableProvided.placeholder}
        </List>
      )
    }
    </Droppable>


  )
}


function elementos_telegram(NuevoElemento,classes){

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#f5f9fa" : "",
  });

  return(

    <Droppable droppableId="Drop_Elementos_telegram" >
    {
      (droppableProvided,snapshot)=>(
        <List component="nav"
                          aria-label="main mailbox folders"
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          >

            <Draggable draggableId={"componenteTelegram-texto"} index={0} >
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                       {...draggableProvided.draggableProps}
                                       ref={draggableProvided.innerRef}
                                       {...draggableProvided.dragHandleProps}
                                    >
                                     <ListItemIcon>
                                       <AssignmentRoundedIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Texto" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteTelegram-archivo"} index={1}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                >
                                     <ListItemIcon>
                                       <ImageRoundedIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Archivo" />
                                   </ListItem>
                                )}
            </Draggable>
            <Draggable draggableId={"componenteTelegram-texto_boton"} index={2}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <ListAltIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Plantilla con boton" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteTelegram-chatcenter"} index={3}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                   >
                                     <ListItemIcon>
                                       <ChatIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="ChatCenter" />
                                   </ListItem>

                                )}
            </Draggable>
            <Draggable draggableId={"componenteTelegram-webservice"} index={4}>
                                 {(draggableProvided)=>(
                                   <ListItem className={classes.borderItem}
                                   {...draggableProvided.draggableProps}
                                   ref={draggableProvided.innerRef}
                                   {...draggableProvided.dragHandleProps}
                                  >
                                     <ListItemIcon>
                                       <HttpIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="WebService" />
                                   </ListItem>
                                )}
            </Draggable>
            {droppableProvided.placeholder}
        </List>
      )
    }
    </Droppable>


  )
}
