import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Typography } from "../../../../../components/Wrappers";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import BloqueCarrusel from './bloque_carrusel'
import DeleteElementos from '../../DeleteElementos';
import PostAddIcon from '@material-ui/icons/PostAdd';
import {useSnackbar } from 'notistack';
import {db} from "../../../../../services/firebase";
import {useAdminBotState} from '../../../contexto/AdminBotContext';
import { useUserState} from "../../../../../context/UserContext";
import DialogElement from '../dialogElement';

import {maximaCantidadElementosCarrousel,
  mensajeTextoLongitudLimites,
  validarTextoMensajeErrorEscrito,
  mensajeTextoErrorErrores
} from './../validaciones';

import log_it from './../../../../../services/log/log'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    loadingChip:{
      color:"#ffff",
    }
  }),
);

export function Carrusel(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    const { enqueueSnackbar } = useSnackbar();
    var { datos,user } = useUserState();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [loadCarrusel,setLoadCarrusel] = React.useState(false);


    /*variables de datos*/
    const [mensajeError,setMensajeError]=React.useState("");
    const [atributo,setAtributo]= React.useState("");
    const [intentos,setIntentos]= React.useState(0);
    const [cargando,setCargando]=React.useState(false);

    const [arregloModulos,setArregloModulos]= React.useState([]);
    const [actualizarArregloModulos,setActualiarArregloModulos]= React.useState(true);

    const [mensajeErrorCodigoValidacion,setMensajeErrorCodigoValidacion]=React.useState(-1);

    const [items,setItems]=React.useState([]);
    const [enviar_a,setEnviar_a]=React.useState("");

    const cambiarEnviarA=(event)=>{
      if(event.target.value!==undefined)
      {
          setEnviar_a(event.target.value);
      }
    }

    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }
    },[]);

    useEffect(() =>{
      if(bloques!==null)
      {
          let lista_items=[]
          bloques.forEach((element, index, array) => {
              lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
          });
          setItems(lista_items);
      }
    },[bloques]);

    /*inicializar vaiables*/
    useEffect(() =>{

      setLoadCarrusel(false);
      if(props.datos)
      {
        setMensajeError(props.datos.mensaje_error===undefined?"":props.datos.mensaje_error);
        setAtributo(props.datos.atributo===undefined?"":props.datos.atributo);
        setIntentos(props.datos.intento===undefined?"":props.datos.intento);
        setEnviar_a((props.datos.enviar_a===""||props.datos.enviar_a===undefined)?"0":props.datos.enviar_a)

        let arreglo=JSON.stringify(props.datos.modulos);
        setArregloModulos(JSON.parse(arreglo));

      }

    },[open]);



    const GuardarCambios=()=>{
    /*  console.log("---------------------------------")
      console.log("guardando cmabios");
      console.log(arregloModulos);
      console.log("Props")
      console.log(props.datos.modulos)
      console.log("---------------------------------")
      */
      var temp_enviar_a = ""
      var temp_intentos = 0

      temp_intentos = parseInt(intentos)
      if(isNaN(temp_intentos)){
        temp_intentos=0
      }

      if(enviar_a!="0"){
        temp_enviar_a=enviar_a
      }

      if(arregloModulos.length===0)
      {
        enqueueSnackbar('Debe de exisite almenos un Modulo', {variant: 'error'});
      }else{
        var patch_arregloModulos = arregloModulos
        setCargando(true);

        var carrousel_obj = {
          atributo:atributo,
          elemento:"carrusel",
          intento:temp_intentos,
          enviar_a:temp_enviar_a,
          mensaje_error:mensajeError,
          modulos:patch_arregloModulos
        }
        if(bot_actual.conexion=="instagram"){
          delete carrousel_obj.mensaje_error

          carrousel_obj.modulos.forEach(element=>{
            if(element.default_action!==undefined){

              if(element.default_action.url==""){
                delete element.default_action
              }
            }
          })
        }

        if(props.id_elemento===-1){

          setCargando(false);
          setOpen(false);
          enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});
          props.GuardarNuevoElemento(
          carrousel_obj,props.new_index
          );

        }else{
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
            .set(carrousel_obj,function(error){
              if (error) {
                  setCargando(false);
                  enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
              } else {
                  setCargando(false);
                  enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                  setOpen(false);
                  var obj = {
                    fecha: + new Date(),
                    nombre: "modificar elemento",
                    id_accion: 132,
                    id_bot: bot_actual.id,
                    red_social: bot_actual.conexion,
                    bloque:props.id_bloque,
                    elemento:"carrusel",
                  }
                  log_it(datos.id_empresa,user.id,obj)
              }
            });
        }
     }

    }

    const AgregarModulo=()=>{
      setArregloModulos((prevState)=>{
        if(prevState.length<=10){
          prevState.push({
            buttons:[],
            image_url:"",
            subtitle:"",
            title:"",
          });
          return prevState;
        }
      });
      setActualiarArregloModulos(actualizarArregloModulos?false:true);
    }

    const EliminarModulo=(datos)=>{
      setArregloModulos((prevState)=>{
        prevState.splice(datos.id,1);
        return prevState;
      });
      setActualiarArregloModulos(actualizarArregloModulos?false:true);
    }


    const handleClickOpen = () => {
      setLoadCarrusel(true);
      setOpen(true);
    };

    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);
    };


    return(
        <React.Fragment>

          <DialogElement
            id_elemento={props.id_elemento}
            moverElementos={props.moverElementos}
            moverMasuno={props.MoverMasuno}
            moverMenosuno={props.MoverMenosuno}
            icono={<ViewCarouselIcon/>}
            metodoAbrir={handleClickOpen}
            nombre={props.nombre}
            tamanio={props.tamanio}
            dialogOpen={open}
            dialogClose={handleClose}
            cargando={cargando}
            disabledSave={intentos<0  || (atributo.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributo)))}
            guardar={GuardarCambios}
            eliminar={props.eliminar}
            cerrar={setOpen}
          >
            {(bot_actual.conexion=="instagram")?
            null
            :
            <Grid item xs={12}>
            <TextField
              id="ElTexto_mensaje"
              value={mensajeError}
              onChange={

                e => {
                  var codigoValidacion = validarTextoMensajeErrorEscrito(e,e.target.value,bot_actual.conexion,null)
                  setMensajeErrorCodigoValidacion(codigoValidacion)
                  if(mensajeErrorCodigoValidacion<1){
                    setMensajeError(e.target.value)
                  }
                }
              }
              label="Mensaje de error"
              variant="outlined"
              fullWidth
              multiline
              disabled={datos.permisos.abcbot.bloques!==true}
              inputProps={{
                maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
              }}
              error={

                (mensajeErrorCodigoValidacion!=-1 )
                }
               helperText={
                 mensajeTextoErrorErrores(mensajeErrorCodigoValidacion,bot_actual.conexion)
              }
              />
        </Grid>
            }

                        <Grid item xs={12} sm={4}>
                            <TextField
                              id="ElTexto_atributos"
                              value={atributo}
                              onChange={e => setAtributo(e.target.value)}
                              label="Atributo"
                              variant="outlined"
                              disabled={datos.permisos.abcbot.bloques!==true}
                              fullWidth
                              error={atributo.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributo))}
                              helperText={ atributo.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributo)) &&
                              "El atributo solo pude contener numeros, letras y guiones"}
                              />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                              id="ElTexto_atributos"
                              value={intentos}
                              onChange={e => setIntentos(e.target.value)}
                              variant="outlined"
                              fullWidth
                              label="Intentos"
                              type={"number"}
                              disabled={datos.permisos.abcbot.bloques!==true}
                              error={intentos<0}
                              helperText={intentos<0?"No puede ser negativo":""}
                              />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                            <InputLabel id="select-enviar_a">Enviar a</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined-quil"
                                value={enviar_a}
                                onClick={cambiarEnviarA}
                                label="Enviar"
                            >
                                <MenuItem value="0">
                                    <em>None</em>
                                </MenuItem>
                                {items}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Modulos</Typography>
                        </Grid>

                {  datos.permisos.abcbot.bloques===true &&
                       <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={ arregloModulos.length>=maximaCantidadElementosCarrousel }
                            className={classes.button}
                            endIcon={<PostAddIcon/>}
                            onClick={AgregarModulo}
                            >
                            Agregar Modulo
                          </Button>
                        </Grid>}

                        <BloqueCarrusel
                            lista={arregloModulos}
                            editar={setArregloModulos}
                            elemento={props.id_elemento}
                            bloque={props.id_bloque}
                            actualizar={actualizarArregloModulos}
                            eliminar={EliminarModulo}
                            />

                </DialogElement>
        </React.Fragment>
       );

}
