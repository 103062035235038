import React, { useEffect } from "react";
import tinycolor from "tinycolor2";
import Themes from "../themes";
import { db } from "../services/firebase";
import { useMediaQuery } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";

const ThemeStateContext = React.createContext();
const ThemeDispatchContext = React.createContext();

function ThemeProvider({ children }) {
//  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  var [theme, setTheme] = React.useState(Themes.ini);

  useEffect(() =>{
      //obtener el url actual
      const URLdomain = document.domain;
      db.ref('/tema_asignado')
          .orderByChild('domain')
          .equalTo(URLdomain)
          .once('value')
          .then( function (snap) {
              return snap.val()
          })
          .then( function (valor){
              //no existe el tema par aeste dominio
              if ( valor === null ) {
                  setTheme(Themes.default);
              }else{
                let id = ''
                for (var key in valor) {
                   id = key;
                }
                db.ref(`tema/${valor[id].tema}`)
                    .once('value')
                    .then(function (snap){
                      CrearTema(snap.val().tema, setTheme);
                    });
              }
          });


  },[]);

  return (
    <ThemeStateContext.Provider value={theme}>
      <ThemeDispatchContext.Provider value={setTheme}>
        {children}
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
}

function useThemeState() {
  var context = React.useContext(ThemeStateContext);
  if (context === undefined) {
    throw new Error("useThemeState must be used within a ThemeProvider");
  }
  return context;
}

function useThemeDispatch() {
  var context = React.useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error("useThemeDispatch must be used within a ThemeProvider");
  }
  return context;
}

export { ThemeProvider, useThemeState, useThemeDispatch, ThemeStateContext, TemaDark, TemaDefault };

function CrearTema (coloresBase ,tema) {


    const primary = coloresBase.primary;
    const secondary = coloresBase.secondary;
    const warning = coloresBase.warning;
    const success = coloresBase.success;
    const info = coloresBase.info;

    const lightenRate = 10;
    const darkenRate = 15;


  const overrides = {
    typography: {
      h1: {
        fontSize: "3rem"
      },
      h2: {
        fontSize: "2rem"
      },
      h3: {
        fontSize: "1.64rem"
      },
      h4: {
        fontSize: "1.5rem"
      },
      h5: {
        fontSize: "1.285rem"
      },
      h6: {
        fontSize: "1.142rem"
      }
    },
    overrides: {
      MuiCard: {
        root: {
          boxShadow:
            `0px 3px 11px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
        }
      },
      MUIDataTable: {
        paper: {
          boxShadow:
            `0px 3px 11px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
        }
      },
      MuiMenu: {
        paper: {
          boxShadow:
            `0px 3px 11px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
        }
      },
      MuiListItem: {
        root: {
          "&$selected": {
            backgroundColor: tinycolor(primary)
              .lighten(lightenRate)
              .toHexString(),
            "&:hover, &:focus": {
              backgroundColor:tinycolor(primary)
                .lighten(lightenRate)
                .toHexString()
            }
          }
        },
        button: {
          "&:hover, &:focus": {
            backgroundColor:tinycolor(primary)
              .lighten(20)
              .toHexString()
          }
        }
      },
      MuiListItemText:{
        root:{
          "&$selected": {
            color: tinycolor(primary)
              .lighten(lightenRate)
              .toHexString(),
            "&:hover, &:focus": {
              color:tinycolor(primary)
                .lighten(lightenRate)
                .toHexString()
            }
          }
        }
      },
      MuiTableRow: {
        root: {
          height: 56
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: "1px solid rgba(224, 224, 224, .5)",
          padding: "14px 40px 14px 24px"
        },
        head: {
          fontSize: "0.95rem"
        },
        body: {
          fontSize: "0.95rem"
        },
        paddingCheckbox: {
          padding: "0 0 0 15px"
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: coloresBase.background.widget,
        }
      },
      MuiList:{
        root: {
          backgroundColor: coloresBase.background.widget,
        },
      },
      MuiDialogContent:{
        root: {
          backgroundColor: `${coloresBase.background.widget} !important`
        }
      },
      MuiDivider:{
        root: {
          backgroundColor: `${coloresBase.primary} !important`
        },
      }
    }
  };

  const defaultTheme = {
    automataconf:{
      imagenes:{
        bot: coloresBase.imagenes.bot,
        header: coloresBase.imagenes.header,
        login: coloresBase.imagenes.login,
        loginmovil: coloresBase.imagenes.loginmovil
      },
      config:coloresBase.config,
      ready:true,
      colorlogin:coloresBase.colorlogin,
      colores:{
        primary: coloresBase.primary,
        secondary: coloresBase.secondary,
        warning: coloresBase.warning,
        success: coloresBase.success,
        info: coloresBase.info,
        text: {
          primary: coloresBase.text.primary,
          secondary: coloresBase.text.secondary,
          hint: coloresBase.text.hint
        },
        background: {
          default: coloresBase.background.default,
          light: coloresBase.background.light,
          widget: coloresBase.background.widget
        },
      }
    },
    palette: {
      primary: {
        main: primary,
        light: tinycolor(primary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(primary)
          .darken(darkenRate)
          .toHexString()
      },
      secondary: {
        main: secondary,
        light: tinycolor(secondary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(secondary)
          .darken(darkenRate)
          .toHexString(),
        contrastText: "#fffefe"
      },
      warning: {
        main: warning,
        light: tinycolor(warning)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(warning)
          .darken(darkenRate)
          .toHexString()
      },
      success: {
        main: success,
        light: tinycolor(success)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(success)
          .darken(darkenRate)
          .toHexString()
      },
      info: {
        main: info,
        light: tinycolor(info)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(info)
          .darken(darkenRate)
          .toHexString()
      },
      inherit: {
        main: "inherit",
        light: tinycolor("inherit")
          .lighten("inherit")
          .toHexString(),
        dark: tinycolor("inherit")
          .darken("inherit")
          .toHexString()
      },
      text: {
        primary: coloresBase.text.primary,
        secondary: coloresBase.text.secondary,
        hint: coloresBase.text.hint
      },
      background: {
        default: coloresBase.background.default,
        light: coloresBase.background.light,
        paper:coloresBase.background.widget
      }
    },
    customShadows: {
      widget:
        `0px 3px 11px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`,
      widgetDark:
        `0px 3px 18px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`,
      widgetWide:
        `0px 12px 33px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
    }
  };
  tema(createMuiTheme({ ...defaultTheme, ...overrides }));
}


function TemaDark (coloresBase ,tema) {

  const darkModeOverrides = {
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#23232D",
          boxShadow:
            "0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important"
        }
      },
      MuiAppBar: {
        root: {
          backgroundColor: "#23232D !important"
        }
      },
      MuiButton: {
        root: {
          boxShadow: "none !important"
        }
      },
      typography: {
        root: {
          color: "white !important"
        }
      }
    }
  };

  const primary = "#536DFE";
  const secondary = "#EE266D";
  const warning = "#E9B55F";
  const success = "#63C5B5";
  const info = "#AE1ECC";

  const lightenRate = 7.5;
  const darkenRate = 15;

  const defaultTheme = {
    automataconf:{
      imagenes:coloresBase.imagenes,
      colores:coloresBase.colores,
      config: coloresBase.config ,
      ready:true,
      colorlogin: coloresBase.colorlogin
    },
    palette: {
      contrastText: "#fff",
      type: "dark",
      primary: {
        main: primary,
        light: tinycolor(primary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(primary)
          .darken(darkenRate)
          .toHexString()
      },
      secondary: {
        main: secondary,
        light: tinycolor(secondary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(secondary)
          .darken(darkenRate)
          .toHexString(),
        contrastText: "#fff"
      },
      warning: {
        main: warning,
        light: tinycolor(warning)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(warning)
          .darken(darkenRate)
          .toHexString()
      },
      success: {
        main: success,
        light: tinycolor(success)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(success)
          .darken(darkenRate)
          .toHexString()
      },
      info: {
        main: info,
        light: tinycolor(info)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(info)
          .darken(darkenRate)
          .toHexString()
      },
      inherit: {
        main: "inherit",
        light: tinycolor("inherit")
          .lighten("inherit")
          .toHexString(),
        dark: tinycolor("inherit")
          .darken("inherit")
          .toHexString()
      },
      text: {
        primary: "#fff",
        secondary: "#D6D6D6",
        hint: "#D6D6D6"
      },
      background: {
        default: "#13131A",
        light: "#23232D"
      }
    },
    customShadows: {
      widget:
        "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      widgetDark:
        "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      widgetWide:
        "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
    }
  };
  tema(createMuiTheme({ ...defaultTheme, ...darkModeOverrides }));
}



function TemaDefault(coloresBase ,tema) {

  if(coloresBase.background === undefined){
    tema(Themes.default);
    return 0;
  }

  const primary = coloresBase.colores.primary;
  const secondary = coloresBase.colores.secondary;
  const warning = coloresBase.colores.warning;
  const success = coloresBase.colores.success;
  const info = coloresBase.colores.info;

  const lightenRate = 7.5;
  const darkenRate = 15;


  const overrides = {
    typography: {
      h1: {
        fontSize: "3rem"
      },
      h2: {
        fontSize: "2rem"
      },
      h3: {
        fontSize: "1.64rem"
      },
      h4: {
        fontSize: "1.5rem"
      },
      h5: {
        fontSize: "1.285rem"
      },
      h6: {
        fontSize: "1.142rem"
      }
    },
    overrides: {
      MuiCard: {
        root: {
          boxShadow:
            `0px 3px 11px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
        }
      },
      MUIDataTable: {
        paper: {
          boxShadow:
            `0px 3px 11px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
        }
      },
      MuiMenu: {
        paper: {
          boxShadow:
            `0px 3px 11px 0px ${coloresBase.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
        }
      },
      MuiListItem: {
        root: {
          "&$selected": {
            backgroundColor: tinycolor(primary)
              .lighten(lightenRate)
              .toHexString(),
            "&:hover, &:focus": {
              backgroundColor:tinycolor(primary)
                .lighten(lightenRate)
                .toHexString()
            }
          }
        },
        button: {
          "&:hover, &:focus": {
            backgroundColor:tinycolor(primary)
              .lighten(20)
              .toHexString()
          }
        }
      },
      MuiTableRow: {
        root: {
          height: 56
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: "1px solid rgba(224, 224, 224, .5)",
          padding: "14px 40px 14px 24px"
        },
        head: {
          fontSize: "0.95rem"
        },
        body: {
          fontSize: "0.95rem"
        },
        paddingCheckbox: {
          padding: "0 0 0 15px"
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: coloresBase.background.widget,
        }
      },
      MuiList:{
        root: {
          backgroundColor: coloresBase.background.widget,
        },
      },
      MuiDialogContent:{
        root: {
          backgroundColor: `${coloresBase.background.widget} !important`
        }
      },
      MuiDivider:{
        root: {
          backgroundColor: `${coloresBase.primary} !important`
        },
      }
    }
  };



  const defaultTheme = {
    automataconf:{
      imagenes: coloresBase.imagenes,
      config:coloresBase.config,
      ready:true,
      colores:coloresBase.colores,
      colorlogin: coloresBase.colorlogin
    },
    palette: {
      primary: {
        main: primary,
        light: tinycolor(primary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(primary)
          .darken(darkenRate)
          .toHexString()
      },
      secondary: {
        main: secondary,
        light: tinycolor(secondary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(secondary)
          .darken(darkenRate)
          .toHexString(),
        contrastText: "#fffefe"
      },
      warning: {
        main: warning,
        light: tinycolor(warning)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(warning)
          .darken(darkenRate)
          .toHexString()
      },
      success: {
        main: success,
        light: tinycolor(success)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(success)
          .darken(darkenRate)
          .toHexString()
      },
      info: {
        main: info,
        light: tinycolor(info)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinycolor(info)
          .darken(darkenRate)
          .toHexString()
      },
      inherit: {
        main: "inherit",
        light: tinycolor("inherit")
          .lighten("inherit")
          .toHexString(),
        dark: tinycolor("inherit")
          .darken("inherit")
          .toHexString()
      },
      text: {
        primary: coloresBase.colores.text.primary,
        secondary: coloresBase.colores.text.secondary,
        hint: coloresBase.colores.text.hint
      },
      background: {
        default: coloresBase.colores.background.default,
        light: coloresBase.colores.background.light,
        paper:coloresBase.colores.background.widget
      }
    },
    customShadows: {
      widget:
        `0px 3px 11px 0px ${coloresBase.colores.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`,
      widgetDark:
        `0px 3px 18px 0px ${coloresBase.colores.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`,
      widgetWide:
        `0px 12px 33px 0px ${coloresBase.colores.background.widget}, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A`
    }
  };
  tema(createMuiTheme({ ...defaultTheme, ...overrides }));
}
