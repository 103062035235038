import React , { useState, useEffect } from 'react';



//GENERALES
//BOTONES
export const maximaCantidadCaracteresEnBotones = 20


//TEXTO
export const mensajeTextoLongitudLimites={
    'facebook':460,
    'telegram':4096,
    'whatsapp':4096,
    'whatsapp_textoRapido':1020,

    }
export const mensajeTextoContenidoErrores= (codigoError,red_social)=>{
    const objErrores = {
        0:'El campo es obligatorio',
        1:'Longitud máxima del mensaje alcanzada, limite: '+mensajeTextoLongitudLimites[red_social]+' caracteres.'
    }
    var mensajeError = ""
    mensajeError = objErrores[codigoError]
    return(
        mensajeError
    )
}
export const mensajeTextoErrorErrores= (codigoError,red_social)=>{
    const objErrores= {
        0:'El campo es obligatorio si la IA esta activa',
        1:'Longitud máxima del mensaje alcanzada, limite: '+mensajeTextoLongitudLimites[red_social]+' caracteres.',
        2:'El campo es obligatorio',
        3:'',
    }
    var mensajeError = ""
    mensajeError = objErrores[codigoError]
    return(
        mensajeError
    )
}
export const mensajeTextoBotonErrores= (codigoError)=>{
    const objErrores = {
        0:'El campo es obligatorio',
        1:'Longitud máxima del mensaje alcanzada, limite: '+maximaCantidadCaracteresEnBotones+' caracteres.'
    }
    var mensajeError = ""
    mensajeError = objErrores[codigoError]
    return(
        mensajeError
    )

}


export function validarTextoMensajeContenidoEscrito(evento,mensaje,red_social){
/*
--Utilizado para los mensajes

Devuelve -1 si el mensaje cumple las validaciones
Devuelve otro numero si no se cumple una regla
*/
    //si codigoValicadcion es -1 entonces no se rompio ninguna regla
    var codigoValidacion = -1
    var maximaLongitud = mensajeTextoLongitudLimites[red_social]
    if(mensaje.length==0){
        codigoValidacion = 0
    }
    if(mensaje.length>=maximaLongitud){
        codigoValidacion = 1
    }
    return codigoValidacion
}
export function validarTextoMensajeErrorEscrito(evento,mensaje,red_social,IAActivated){
/*
--Utilizado para los mensajes de error
Devuelve -1 si el mensaje cumple las validaciones
Devuelve otro numero si no se cumple una regla
*/
    //si codigoValicadcion es -1 entonces no se rompio ninguna regla
    var codigoValidacion = -1
    var maximaLongitud = mensajeTextoLongitudLimites[red_social]
    if(mensaje.length==0 ){
        if(IAActivated){
            codigoValidacion = 0
        }else{
            if(IAActivated!=null){
                codigoValidacion=2
            }
        }
    }
    if(mensaje.length>maximaLongitud){
        codigoValidacion = 1
    }
    return codigoValidacion
}

export function validarTextoBotonesContenidoEscrito(evento,mensaje,red_social){
/*
--Utilizado para los mensajes

Devuelve -1 si el mensaje cumple las validaciones
Devuelve otro numero si no se cumple una regla
*/
    //si codigoValicadcion es -1 entonces no se rompio ninguna regla
    var contenidoActual = ""
    if (evento!=null){
        contenidoActual = evento.target.value
    }
    //verificar que al principio no se ingrese ninguna forma de espacio en blanco
    var codigoValidacion = -1
    var maximaLongitud = maximaCantidadCaracteresEnBotones
    if(mensaje.length==0){
        codigoValidacion = 0
    }
    if(mensaje.length>=maximaLongitud){
        codigoValidacion = 1
    }
    return codigoValidacion
}

export function validarTextoMensajeReplayError(evento,mensaje,red_social,IAActivated){
    /*
--Utilizado para los mensajes de error
Devuelve -1 si el mensaje cumple las validaciones
Devuelve otro numero si no se cumple una regla
*/
    //si codigoValicadcion es -1 entonces no se rompio ninguna regla
    var codigoValidacion = -1
    var maximaLongitud = mensajeTextoLongitudLimites[red_social]
    if(mensaje.length==0 ){
        if(IAActivated){
            codigoValidacion = 0
        }
    }
    if(mensaje.length>=maximaLongitud){
        codigoValidacion = 1
    }
    return codigoValidacion
}
//COMPONENTES

//CARROUSEL
export const maximaCantidadElementosCarrousel = 10
export const maximaCantidadBotonesEnModulo = 3
