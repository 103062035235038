import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import {
    Grid,
    TextField as Input,
  } from "@material-ui/core";
import { Button, Typography,Avatar } from "../../components/Wrappers";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useUserState,userNewPassword} from "../../context/UserContext";
import {db} from "../../services/firebase";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@material-ui/styles";
import ReactApexChart from 'react-apexcharts'
import CircularProgress from '@material-ui/core/CircularProgress';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import log_it from './../../services/log/log';

function ReportsOrdenCompra ()
{
    var classes = useStyles();
    var theme = useTheme();


    const [nombrebot,setNombrebot]=React.useState("");
    const [listabot,setListabot]=React.useState([]);

    const [mostrarSlect,setMostrarSelect]=React.useState(true);
    var { datos, user } = useUserState();

    const [progreso,setProgerso] = React.useState(false);
    const [columnasClientes,setColumnasClientes] = React.useState([
        {
            name: "id",
            label: "Id orden",
            options: {
                    filter: true,
                    sort: true,
                }
        },
        {
            name: "fbid",
            label: "facebook id",
            options: {
                    filter: true,
                    sort: true,
                }
        },        
        {
            name: "fecha_compra",
            label: "Fecha de compra" ,
            options: {
                    filter: true,
                    sort: true,
                }
        },
        {
            name: "responsable",
            label: "Responsable" ,
            options: {
                    filter: true,
                    sort: true,
                }
        },
        {
            name: "estado",
            label: "Estado" ,
            options: {
                    filter: true,
                    sort: true,
                }
        },
        {
            name: "articulos",
            label: "Articulos" ,
            options: {
                    filter: true,
                    sort: true,
                }
        },  

    ]);
    const [datosClientes,setDatosClientes] = React.useState([]);


    //Obtener informacion de los bots
    useEffect(() =>{

       
        let conexion_reporte=db.ref("bots/"+datos.id_empresa).on("value",function(snapshot){
          
            let bots=[];
            snapshot.forEach(function(data){   
                 if(data.val().conexiones.facebook){       
                    bots.push(<MenuItem key={data.key} value={{valor:data.key,texto:data.val().nombre}}>{data.val().nombre}</MenuItem>);
                 }
            });
            setListabot(bots);
            

        });

   

        return ()=>{
            db.ref("bots/"+datos.id_empresa).off();
            db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+nombrebot).off();
          }


    },[]);


    
    function iniciarDatos(id_bot){
       
        db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+id_bot).
        on("value",function(snapshot){

            let active=0;
            let succesful=0;
            let cancel=0;

            snapshot.forEach(function(data){    
                if(data.val().estado==="active"){active++};
                if(data.val().estado==="succesful"){succesful++};
                if(data.val().estado==="cancel"){cancel++};

                let articulos="";
                for( var key in data.val().articulos)
                {  
                    for( var keyA in data.val().articulos[key])
                    {  
                       // console.log("item",data.val().articulos[key][keyA]);
                        if(articulos===""){
                            articulos+=keyA+":"+data.val().articulos[key][keyA];
                        }else{
                            articulos+=","+keyA+":"+data.val().articulos[key][keyA];
                        }
                    }

                   
                }

                datosClientes.push({
                    id:data.key,
                    fecha_compra:new Date(data.val().fecha_compra).toLocaleString('es-MX'),
                    responsable:data.val().responsable==="usuario"? "":data.val().responsable ,
                    estado:data.val().estado==="active"?"Activo": 
                            data.val().estado==="succesful"?"Procesado":  
                                "Cancelado"  
                    ,
                    fbid:data.val().fbid,              
                    articulos:articulos                   
                });

            });
            var obj = {
                fecha: + new Date(),
                nombre: "generar reporte compras",
                id_accion: 504,
                id_bot: id_bot,
                red_social: 'facebook',
            }
            log_it(datos.id_empresa,user.id,obj)
            setProgerso(false);
            setMostrarSelect(true);
            setSeries([active,succesful,cancel]);
        });
    }


    var camibarBot = (event)=> {
        //console.log(event.target.value);
        db.ref('detalle_compras/'+datos.id_empresa+"/facebook/"+setNombrebot.valor).off();
        setNombrebot(event.target.value);  
        setMostrarSelect(false);
        setProgerso(true);
        iniciarDatos(event.target.value.valor);   
    };

     


    const  [series,setSeries]=React.useState([0, 0, 0]);
    const  [options,setOptions]=React.useState({
        chart: {
            width: 380,
            type: 'pie',
          },
          labels: ['Activos', 'Procesados', 'Cancelados'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
    });


   return (

        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Widget
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu
                        title={'Reporte Compras'}
                >
                        <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                            <Grid item xs={12}  >
                                <Typography className={classes.LeyendaPorcentaje}>Seleccione el bot de Facebook</Typography>
                            </Grid>
                        </Grid>
                        {
                            mostrarSlect &&
                            <div>
                                <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                                    <Grid item xs={12}  >
                                        <FormControl variant="outlined" className={classes.centrar}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Age"
                                                value={nombrebot}
                                                onChange={camibarBot}
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                        {listabot}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                </Grid>    
                            </div>
                                
                        }
                        {  progreso &&  <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                            <Grid item xs={12}  >
                                <FormControl variant="outlined" className={classes.centrar}>
                                    <CircularProgress />
                                </FormControl>
                            </Grid>
                        </Grid>}                    
                            
                </Widget>
            </Grid>


            <Grid item xs={12}>
                    <Widget
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu
                    >
                            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"}>                      
                                 <Grid item xs={12}>                                
                                    <ReactApexChart options={options} series={series} type="pie" height={350} />
                                </Grid>                                
                            </Grid>
                    </Widget>
            </Grid>



            <Grid item xs={12}>
                    <Widget
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu
                    >
                            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"}>                      
                                 <Grid item xs={6} >
                                 <Typography className={classes.LeyendaPorcentajeBot}>{datosClientes.length} ordenes totales </Typography>
                                </Grid>                          
                                <Grid item xs={6} >
                                    <ShoppingCartIcon style={{ fontSize: 50 }} /*color={theme.palette.primary.light}*//>
                                </Grid>
                            </Grid>



                    </Widget>
            </Grid>


            <Grid key={"tabla_clientes"} item xs={12} md={12}>             
                <MUIDataTable
                    title="Ordenes de Compra"
                    data={datosClientes}
                    columns={columnasClientes}
                    options={{
                        selectableRows: "none",
                    }}
                /> 
            </Grid>
 
        </Grid>
   );

}



export default ReportsOrdenCompra;
