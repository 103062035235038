import React from "react";
import { useUserState } from "../context/UserContext";
import { cargando_info } from "../services/automata"
import { CircularProgress } from "@material-ui/core";

function Root ({ children }) {


        var { authReady } = useUserState();
        let cargando = cargando_info.find( element => element.domain === document.domain );
        if(!authReady)
        {

            return (<div> {cargando === undefined ? "Loading data user..." :
                <>
                <div style={{textAlign:"center"}}>
                    <img width="45%"  src={cargando.url}/>
                </div>
                <div style={{textAlign:"center"}}>
                    <CircularProgress />
                </div>
                </>
              }
            </div>
            );
        }else{
        return (<div>{children}</div>);

        }

}



export default Root;
