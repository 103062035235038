import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import AddBoxIcon from '@material-ui/icons/AddBox';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Box from '@material-ui/core/Box';
import QueueIcon from '@material-ui/icons/Queue';
import { Typography } from "../../../../../components/Wrappers";
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import { useAdminBotDispatch,
    botActual,
    useAdminBotState,
    bloques
} from '../../../contexto/AdminBotContext';
import Modulos from './Modulos';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',

        backgroundColor: theme.palette.background.paper,
      },
      gridList: {

        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },
      title: {
        color: theme.palette.primary.light,
      },
      titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
      formControl: {
        width: "100%",
      },

      margen:{
        padding: theme.spacing(1),
      },

      colorDelete:{
          color:"#dc004e",
      }

  }),
);


function BloqueCarrusel(props){

    const classes = useStyles();
    var adminBotDispach=useAdminBotDispatch();
    var {bot_actual,bloques} =useAdminBotState();


    const [modulos,setModulos]=React.useState([]);



    useEffect(() =>{

      let arreglo=[];
      props.lista.forEach((item, i) => {

          arreglo.push(
            <GridListTile   key={"key-modulos_"+props.elemento+"-"+props.bloque+"-"+i} >
              {bot_actual.conexion=="instagram"?
                <Modulos
                url={item.image_url}
                titulo={item.title}
                subtitulo={item.subtitle}
                default_action={item.default_action}
                botones={item.buttons}
                index={i}
                editar={props.editar}
                elemento={props.elemento}
                bloque={props.bloque}
                eliminar={props.eliminar}
                />
              :
                <Modulos
                url={item.image_url}
                titulo={item.title}
                subtitulo={item.subtitle}
                botones={item.buttons}
                index={i}
                editar={props.editar}
                elemento={props.elemento}
                bloque={props.bloque}
                eliminar={props.eliminar}
                />
              }
            </GridListTile >
          );
      });
      setModulos(arreglo);
    },[props.lista,props.actualizar]);




    return(

      <Grid item xs={12} >
               <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                  {modulos}
               </GridList>
      </Grid>
);


}

export default BloqueCarrusel;
